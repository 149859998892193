import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import config from './YodaTooltip.config';
import * as styles from './YodaTooltip.css';
import Link from '../Link/Link';

const cx = classNames.bind(styles);
/**
 * tooltip placement
 *
 * TOPLEFT -> render tooltip on top of the required element positioned at left
 * BOTTOMRIGHT -> render tooltip at the bottom of the required element positioned at right
 * TOPRIGHT -> render tooltip on top of the required element positioned at right
 * BOTTOMLEFT -> render tooltip at the bottom of the required element positioned at left
 *
 * */
class YodaTooltip extends PureComponent {
    static propTypes = {
        /*
          Tooltip element on which we wants to get tooltip
        */
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
        /**
         * Set style that would be appended to tooltip body and element
         * @type {String}
         */
        tooltipBodyClassName: PropTypes.string,
        tooltipTextClass: PropTypes.string,
        /* callback function to display tooltip data that we wants to display inside tooltip */
        renderTooltipContents: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        /* set style that would be appended to tooltip */
        tooltipContentClass: PropTypes.string,
        /* Tolltip direction */
        direction: PropTypes.oneOf([
            config.direction.BOTTOMRIGHT,
            config.direction.BOTTOMLEFT,
            config.direction.TOPRIGHT,
            config.direction.TOPLEFT,
            config.direction.RIGHT,
            config.direction.RIGHTALIGN,
            config.direction.LEFTALIGN,
        ]),
        tooltipPlacement: PropTypes.oneOf([
            config.tooltipPlacement.RIGHT,
            config.tooltipPlacement.LEFT,
            config.tooltipPlacement.MIDDLE,
        ]),
        triggerEventName: PropTypes.oneOf([
            config.triggerEventName.CLICK,
            config.triggerEventName.MOUSEOVER,
        ]),
        callBackFun: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        onClickHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        // stayInView: PropTypes.bool,
        stayOnContentHover: PropTypes.bool,
        theme: PropTypes.string,
        /* By default onclick of tooltip conent Tooltip will get hidden - Send false to disable this feature */
        disableHideOnContentClick: PropTypes.bool,
        tabbable: PropTypes.bool,
        btnActiveClass: PropTypes.string,
        activityMapId: PropTypes.string,
        parentActivityMapId: PropTypes.string,
        tooltipType: PropTypes.string,
        linkName: PropTypes.string,
        controlledIsOpen: PropTypes.bool,
        clickTriggerTooltipOnClick: PropTypes.func,
        isListClicked: PropTypes.bool,
        isAccountHover: PropTypes.bool,
        shouldToggleOnMouseOver: PropTypes.bool,
        delay: PropTypes.number,
        disabled: PropTypes.bool,
        // Custom tooltip
        tooltipData: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string])
            .isRequired,
        automationId: PropTypes.string,
        customTooltipTheme: PropTypes.objectOf(PropTypes.string),
        dynamicPosition: PropTypes.bool,
        enableOverlay: PropTypes.bool,
        /**
         * If true tooltip content will display on click. By default it will open on mouseOver
         * Pass a function to get a callback on click of tooltipText
         */
        onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        /**
         * If true tooltip content will display on mouseOver
         * Pass a function to get a callback on mouseOver of tooltipText
         */
        onMouseOver: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        visibleOnLoad: PropTypes.bool,
    };

    static defaultProps = {
        placement: 'topright',
        children: 'tooltip',
        tooltipBodyClassName: '',
        tooltipTextClass: '',
        renderTooltipContents: '',
        tooltipContentClass: 'tooltip',
        direction: 'Bottomright',
        tooltipPlacement: 'Right',
        triggerEventName: config.triggerEventName.CLICK,
        callBackFun: () => {},
        onClickHandler: null,
        // stayInView: false,
        stayOnContentHover: false,
        theme: '',
        tabbable: false,
        btnActiveClass: '',
        activityMapId: null,
        parentActivityMapId: null,
        tooltipType: 'button',
        linkName: null,
        controlledIsOpen: false,
        clickTriggerTooltipOnClick: null,
        isListClicked: null,
        isAccountHover: false,
        shouldToggleOnMouseOver: false,
        disabled: false,
        delay: 0,
        // Custom Tooltip
        automationId: null,
        customTooltipTheme: {},
        dynamicPosition: true,
        enableOverlay: false,
        onClick: false,
        onMouseOver: false,
        visibleOnLoad: false,
    };

    containerClicked = false;

    constructor(props) {
        super(props);
        const { visibleOnLoad } = this.props;
        this.state = {
            tooltipPosition: '',
            isOpen: false,
            contentStyle: {},
            isVisible: visibleOnLoad,
        };
        this.getPosition = this.getPosition.bind(this);
        this.onClickTooltip = this.onClickTooltip.bind(this);
        this.onClickHandle = this.onClickHandle.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.containerClicked = false;
    }

    componentDidMount() {
        this.setTooltipPosition();
    }

    componentWillReceiveProps(nextProps) {
        // manually toggle tooltip
        if (this.props.controlledIsOpen !== nextProps.controlledIsOpen) {
            this.toggleTooltip(nextProps.controlledIsOpen);
        }
        if (
            nextProps.isListClicked &&
            this.props.isListClicked !== nextProps.isListClicked &&
            this.state.isOpen
        ) {
            this.setState({ isOpen: false });
        }
        if (this?.props?.disabled !== nextProps?.disabled) {
            if (this?.props?.disable) {
                this.setState({ isOpen: false });
            }
        }
    }

    componentWillUnmount() {
        this.removeOutsideClickListener();
    }

    onClickTooltip(event) {
        if (!this.props.disabled) {
            if (this.props.clickTriggerTooltipOnClick) {
                // custom function to be called when clicking on click-triggered tooltip
                this.props.clickTriggerTooltipOnClick(event);
            }
            event.nativeEvent.preventDefault();
            event.stopPropagation();
            if (this.props.triggerEventName !== event.type) return;
            // event.nativeEvent.stopImmediatePropagation();
            const position = this.getPosition();
            this.setState({
                tooltipPosition: position,
            });
            this.toggleTooltip(!this.state.isOpen);

            /* if (!this.props.stayInView) {
                this.toggleTooltip(this.state.isOpen);
            } else {
                this.toggleTooltip(!this.state.isOpen);
            } */
            if (this?.props?.delay) {
                setTimeout(() => {
                    this.toggleTooltip(!this.state.isOpen);
                }, this?.props?.delay);
            }
        }
    }

    onClickHandle(e) {
        if (this.props.onClickHandler) {
            this.props.onClickHandler(e);
        }
    }

    getPosition() {
        let placement;
        if (this.tooltip) {
            const position = this.tooltip.getBoundingClientRect();
            const popupTop = position.top;
            const popupLeft = position.left;
            const popupWidth = position.width;
            const popupHeight = position.height;
            // const popupParentWidth = position.width;
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const topBoundary = popupTop - popupHeight;
            const leftBound = popupLeft - popupWidth;
            const bottomBoundary = popupTop + popupHeight;
            const leftBoundary = popupLeft > 0;
            const rightBoundary = popupLeft + popupWidth < windowWidth;
            if (bottomBoundary < windowHeight) {
                // leftBoundary>0
                if (leftBoundary && rightBoundary) {
                    placement = 'bottom';
                }
            } else if (topBoundary > 0) {
                /* istanbul ignore next */
                if (leftBoundary && rightBoundary) {
                    placement = 'top';
                }
            } else if (leftBound > 0) {
                placement = 'left';
            } else {
                placement = 'right';
            }
        }
        return placement;
    }

    setContainerClicked = () => {
        this.containerClicked = true;
    };

    addOutsideClickListener = () => {
        if (!__SERVER__) {
            document.addEventListener(
                'click',
                this.props.customToolTip ? this.toggleDisplayContent : this.toggleTooltip,
                false
            );
        }
    };

    removeOutsideClickListener = () => {
        if (!__SERVER__) {
            document.removeEventListener(
                'click',
                this.props.customToolTip ? this.toggleDisplayContent : this.toggleTooltip,
                false
            );
        }
    };

    handleMouseOver() {
        if (!this?.props?.disabled) {
            this.setState({ isOpen: true });
        }
    }

    handleMouseLeave() {
        this.setState({ isOpen: false });
    }

    toggleTooltip(value) {
        let toggle = true;
        const { disableHideOnContentClick = false } = this.props;
        // If disableHideOnContentClick flag is true and user clicks on tooltip content then tooltip will not be hidden.
        /* istanbul ignore next */
        if (disableHideOnContentClick && this.containerClicked) {
            this.containerClicked = false;
            toggle = false;
        }
        /* istanbul ignore next */
        if (toggle) {
            let placeValue = false;
            if (typeof value === 'boolean') {
                placeValue = value;
            }

            if (this.props.triggerEventName === config.triggerEventName.CLICK) {
                placeValue ? this.addOutsideClickListener() : this.removeOutsideClickListener();
            }
            this.setState(
                {
                    isOpen: placeValue,
                },
                () => {
                    this.props.callBackFun(this.state.isOpen);
                }
            );
        }
    }

    /** Custom Component start */
    getTooltipPosition = () => {
        const { placement: placementProp } = this.props;
        const { placement: placementState } = this.state;
        const contentStyle = {};
        let placement = placementState || placementProp;
        /* istanbul ignore next */
        try {
            let totalHeightOfContent;
            const { width: bodyWidth } = document.body.getBoundingClientRect();

            const {
                bottom: contentBottom,
                height: contentHeight,
                top: contentTop,
                width: contentWidth,
            } = this.tooltipContentRef.getBoundingClientRect();

            const {
                bottom: tooltipTextBottom,
                left: tooltipTextLeft,
                right: tooltipTextRight,
                top: tooltipTextTop,
                width: tooltipTextWidth,
            } = this.tooltipTextRef.getBoundingClientRect();

            const windowHeight =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight;

            // If content width is greater than body width then body width will be considered.
            if (contentWidth > bodyWidth) {
                contentStyle.width = bodyWidth;
                contentStyle.right = tooltipTextRight - bodyWidth;
                contentStyle.margin = 0;
            } else if (contentWidth < tooltipTextWidth) {
                contentStyle.left = 0;
            } else {
                // For center aligning tooltip content
                const tooltipHalfWidth =
                    Math.round(contentWidth / 2) - Math.round(tooltipTextWidth / 2);
                const tooltipRightSpace = bodyWidth - tooltipTextRight;
                const suffLeftSpace = tooltipHalfWidth < tooltipTextLeft;
                const suffRightSpace = tooltipHalfWidth < tooltipRightSpace;

                if (suffLeftSpace && suffRightSpace) {
                    contentStyle.left = -tooltipHalfWidth;
                } else if (!suffLeftSpace) {
                    contentStyle.left = -tooltipTextLeft;
                } else if (!suffRightSpace) {
                    delete contentStyle.left;
                    contentStyle.right = -tooltipRightSpace;
                }
            }

            if (contentTop < tooltipTextTop) {
                totalHeightOfContent = contentHeight + (contentBottom - tooltipTextTop);
                placement = tooltipTextTop - totalHeightOfContent > 0 ? 'top' : 'bottom';
            } else {
                totalHeightOfContent = contentHeight + (contentTop - tooltipTextBottom);
                placement =
                    tooltipTextBottom + totalHeightOfContent < windowHeight ? 'bottom' : 'top';
            }

            return { contentStyle, placement };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error in Tooltip component', error);
        }

        return { contentStyle, placement };
    };

    getTooltipText = () => {
        const {
            children,
            customTooltipTheme: { tooltipTextClass },
            enableOverlay,
            onClick,
            disabled,
        } = this.props;

        const { isVisible } = this.state;

        const textAdditionalProps = {};
        const displayContent = () => this.toggleDisplayContent(true);
        const removeContent = () => this.toggleDisplayContent(false);

        if (onClick) {
            textAdditionalProps.onClick = () => this.toggleDisplayContent(!isVisible);

            if (!enableOverlay) {
                isVisible ? this.addOutsideClickListener() : this.removeOutsideClickListener();
            }
        } else {
            textAdditionalProps.onMouseOver = displayContent;
            textAdditionalProps.onMouseLeave = removeContent;
        }
        return (
            <button
                className={cx('tooltipText', tooltipTextClass)}
                ref={(tooltipTextRef) => {
                    this.tooltipTextRef = tooltipTextRef;
                }}
                type="button"
                {...textAdditionalProps}
                disabled={disabled}
            >
                {children}
            </button>
        );
    };

    setTooltipPosition = () => {
        const { dynamicPosition } = this.props;
        const { isVisible } = this.state;

        if (isVisible && dynamicPosition) {
            const { contentStyle, placement } = this.getTooltipPosition();

            this.setState({
                contentStyle,
                placement,
            });
        }
    };

    toggleDisplayContent = (visibility) => {
        const { onClick, onMouseOver } = this.props;
        const { isVisible } = this.state;
        const tooltipCallback = onClick || onMouseOver;
        let toggle = true;

        if (this.containerClicked) {
            toggle = false;
            this.containerClicked = false;
        }

        if (typeof visibility !== 'boolean') {
            // eslint-disable-next-line no-param-reassign
            visibility = false;
        }
        /* istanbul ignore next */
        if (toggle && visibility !== isVisible && this.tooltipWrapperRef) {
            this.setState(
                {
                    isVisible: visibility,
                    contentStyle: {},
                },
                () => {
                    if (typeof tooltipCallback === 'function') {
                        tooltipCallback(visibility);
                    }

                    this.setTooltipPosition();
                }
            );
        }
    };

    overlayClicked = () => {
        this.containerClicked = false;
        this.toggleDisplayContent(false);
    };
    /** Custom coimponent End */

    render() {
        if (!this.props.customToolTip) {
            let btnActiveClass;
            if (this.state.isOpen) {
                btnActiveClass = this.props.btnActiveClass;
            }
            const {
                children,
                tooltipBodyClassName,
                theme,
                tooltipTextClass,
                renderTooltipContents,
                tooltipPlacement,
                direction,
                tooltipContentClass,
                triggerEventName,
                tabbable,
                activityMapId,
                parentActivityMapId,
                tooltipType,
                linkName,
                stayOnContentHover,
                isAccountHover,
                shouldToggleOnMouseOver = false,
            } = this.props;
            const tooltipClassNames = `${dt(['relative', 'inline-block', 'z-[4]'])} ${cx(
                'TooltipWrapper',
                tooltipBodyClassName,
                {
                    accountHover: isAccountHover,
                }
            )}`;
            const tooltipClassname = cx(tooltipTextClass, 'tooltip');
            const tooltipContentsClassName = `${dt([
                'absolute',
                'h-auto',
                'rounded-md',
                'border',
                'border-solid',
                'z-[1]',
                'text-gray-60',
                'bg-white',
                'text-sm',
                'whitespace-normal',
            ])} ${cx('tooltip-text', theme, tooltipContentClass, direction, tooltipPlacement)}`;
            const tabbableProps = !tabbable ? { tabIndex: -1 } : {};
            const additionalProps = activityMapId
                ? {
                      onClick: this.setContainerClicked,
                      id: activityMapId,
                  }
                : {
                      onClick: this.setContainerClicked,
                  };
            const contentHandlerProps =
                triggerEventName === config.triggerEventName.MOUSEOVER && stayOnContentHover
                    ? {
                          onMouseOver: this.handleMouseOver,
                          onMouseLeave: this.handleMouseLeave,
                          onClick: this.handleMouseLeave,
                      }
                    : '';
            const handlerProps =
                triggerEventName === config.triggerEventName.MOUSEOVER
                    ? {
                          onMouseOver: shouldToggleOnMouseOver
                              ? this.handleMouseOver
                              : this.onClickTooltip,
                          onMouseLeave: shouldToggleOnMouseOver
                              ? this.handleMouseLeave
                              : this.toggleTooltip,
                          onClick: this.onClickHandle,
                      }
                    : {
                          onClick: this.onClickTooltip,
                      };
            const parentActivityProps = parentActivityMapId ? { id: parentActivityMapId } : {};
            const linkProps =
                tooltipType === config.tooltipType.LINK
                    ? {
                          href: linkName,
                      }
                    : {};
            return (
                <div
                    className={tooltipClassNames}
                    ref={(node) => {
                        this.tooltip = node;
                    }}
                    onMouseDown={this.getPosition}
                    {...parentActivityProps}
                >
                    <Link
                        type={tooltipType}
                        className={`${cx(tooltipClassname, btnActiveClass)} ${dt(['smMd:w-full'])}`}
                        {...handlerProps}
                        {...tabbableProps}
                        {...linkProps}
                    >
                        {children}
                    </Link>
                    <span>
                        <div
                            {...additionalProps}
                            className={tooltipContentsClassName}
                            {...contentHandlerProps}
                            style={this.state.isOpen ? { display: 'block' } : { display: 'none' }}
                        >
                            {renderTooltipContents}
                        </div>
                    </span>
                </div>
            );
        } // Custom Tooltip
        const {
            automationId,
            customTooltipTheme: { tooltipContentClass, tooltipWrapperClass },
            disableHideOnContentClick = true,
            enableOverlay,
            onClick,
            tooltipData,
        } = this.props;

        const { contentStyle, isVisible, placement } = this.state;

        const tooltipWrapperClasses = `${dt(['inline-block', 'relative'])} ${cx(
            'tooltip-wrapper',
            placement,
            tooltipWrapperClass,
            {
                'display-arrow': isVisible,
                'enable-overlay': enableOverlay,
            }
        )}`;

        const tooltipContentClasses = `${dt([
            'absolute',
            'bg-white',
            'rounded-sm',
            'shadow-elevation2',
            'text-left',
        ])} ${cx('tooltip-content', placement, tooltipContentClass, {
            'enable-overlay': enableOverlay,
        })}`;
        const enableOverlayStyles = enableOverlay && dt(['visible']);
        const noEnableOverlayStyles = !enableOverlay && dt(['invisible']);
        const overlayDesignClasses = `${dt(['fixed', 'h-full', 'w-full', 'z-[40]'])} ${cx(
            'overlay-design',
            enableOverlayStyles,
            noEnableOverlayStyles,
            {
                'enable-overlay': enableOverlay,
            }
        )}`;

        const containerProps = {
            className: tooltipContentClasses,
            onClick: disableHideOnContentClick && onClick ? this.setContainerClicked : null,
            ref: (tooltipContentRef) => {
                this.tooltipContentRef = tooltipContentRef;
            },
            style: contentStyle,
        };

        if (disableHideOnContentClick && onClick) {
            containerProps.onClick = this.setContainerClicked;
        }

        return (
            <div
                className={tooltipWrapperClasses}
                data-automation-id={automationId}
                ref={(tooltipWrapperRef) => {
                    this.tooltipWrapperRef = tooltipWrapperRef;
                }}
            >
                {this.getTooltipText()}
                {isVisible && (
                    <div {...containerProps}>
                        {typeof tooltipData === 'function' ? tooltipData() : tooltipData}
                    </div>
                )}
                {isVisible && enableOverlay && (
                    <button
                        aria-label="dismiss overlay"
                        className={overlayDesignClasses}
                        onClick={this.overlayClicked}
                        type="button"
                    />
                )}
            </div>
        );
    }
}
export default YodaTooltip;

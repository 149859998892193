/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _values from 'lodash/values';
import classNames from 'classnames/bind';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import List from 'yoda-core-components/lib/components/List/List';
import Button from 'yoda-core-components/lib/components/Button/Button';
import ThrottleFeatureRender from '../ThrottleFeatureRender/ThrottleFeatureRender';
import YodaTooltip from '../YodaTooltip/YodaTooltip';
import Link from '../Link/Link';
import RewardsInfoOnHover from '../RewardsInfoOnHover/RewardsInfoOnHover';
import {
    customStepUp,
    openSignInSlider,
    showLogoutToolTip,
    openAccountMenuPage,
} from '../../actions/SignInSliderActions';
import selectLazy from '../../lazy/selectors/selectLazy';
import CAMNavWrapper from '../CAMNav/CAMNavLazy';
import YodaTooltipLazy from '../YodaTooltip/HeaderChunksAndFooterLazy';
import {
    REWARD_PERSONALIZATION_DP_REWARDS_DETAILS,
    REWARD_PERSONALIZATION_POINTS,
    REWARD_PERSONALIZATION_REWARDS_MEMBER,
    REWARD_PERSONALIZATION_REWARD_AVAILABLE,
    REWARD_PERSONALIZATION_MY_ACCOUNT,
} from '../../common/Constants';
import Account from '../../assets/svg/account-icon-filled.svg';
import config from './Header.config';
import * as styles from './Header.css';

const cx = classNames.bind(styles);

const RenderHeaderAccount = (props) => {
    const accounts = useSelector(
        (state) =>
            state.accounts || {
                userProfile: {
                    firstName: null,
                    accountId: null,
                    rewardsStatus: null,
                    userState: null,
                    totalCerts: null,
                },
            }
    );
    const preferences = useSelector((state) => state?.context?.preferences || {});
    const featureFlags = useSelector((state) => state?.context?.featureFlags || {});
    const deviceType = useSelector((state) => state?.context?.deviceType || {});
    const headerLoaded = useSelector((state) => selectLazy(state).headerLoad || false);
    const showWelcomeToolTip = useSelector(
        (state) =>
            (state.signInSliderDetails && state.signInSliderDetails.showWelcomeToolTip) || false
    );
    const rewardsHoverInfoMessagesTexts = useSelector(
        (state) => _get(state, 'context.messagesTexts.rewardsInfoOnHover', {}),
        shallowEqual
    );

    const dispatch = useDispatch();

    const shouldShowsignInReminder = () => {
        let showTooltip = false;
        let isSuccess = false;
        const { pageName } = props;
        if (
            !__SERVER__ &&
            featureFlags.enableSessionToolTip &&
            deviceType.isDesktop &&
            pageName === 'HOME'
        ) {
            const isSessionActive = LocalStorage.getData('activeSession', true);
            /* istanbul ignore next */
            if (!isSessionActive) {
                showTooltip = true;
                isSuccess = LocalStorage.setData('activeSession', true, true, '', false, 1440);
            }
        }
        return showTooltip && isSuccess;
    };

    const [showsignInReminder, setShouldShowsignInReminder] = useState(shouldShowsignInReminder());
    const [isListClicked, setIsListClicked] = useState(false);
    const [isCamNavHovered, setIsCamNavHovered] = useState(false);

    const styleTooltip = props.showLoader
        ? cx('tooltipMenuWrapper', 'tooltipWithLoader')
        : cx('tooltipMenuWrapper');

    const { isUserLoggedIn } = props;

    const triggerSignInSlider = (isCreateAccount = false, nextUrl, isLogout = false) => {
        const isHideAccountMenu = _get(featureFlags, 'isHideAccountMenuSlider', false);
        const customizeConfig = {
            forceLogin: true,
            skipOnAuthenticationSuccess: true,
            isHideAccountMenu: true,
            postAuthenticationUrl: nextUrl,
            isSliderCustomized: true,
            openCreateAccount: isCreateAccount,
        };
        /* istanbul ignore else */
        if (featureFlags.enableSignInSlider) {
            /* istanbul ignore else */
            if (!isLogout) {
                isHideAccountMenu
                    ? dispatch(customStepUp(customizeConfig))
                    : dispatch(openSignInSlider(isCreateAccount, nextUrl, isLogout));
            } else {
                dispatch(showLogoutToolTip());
            }
            // Tell link component to not redirect
            return false;
        }
        if (isCreateAccount) {
            return '/createaccount'; // redirect through Link component to /createaccount
        }
        if (isLogout) {
            return '/sessiontimeout'; // redirect through Link component to /sessiontimeout
        }
        return '/signin'; // redirect through Link component to /signin
    };
    /* istanbul ignore next */
    const headerAccountMenuClickHandler = () => {
        // user_state HOT or WARM (loggedIn): display account nav
        // user_state COLD: display signin slider
        if (isUserLoggedIn) {
            dispatch(openAccountMenuPage());
        } else {
            triggerSignInSlider(false, null, false);
        }
    };
    /* istanbul ignore next */
    const renderRewardsInfoInAccountHover = () => {
        const userName =
            _get(accounts, 'userProfile.firstName', '') || config.accountInfo.myAccountLabel;
        const rewardsDetails = TokenProvider.get('DP_REWARDS_DETAILS') || {};
        return <RewardsInfoOnHover userName={userName} rewardsDetails={rewardsDetails} />;
    };
    /* istanbul ignore next */
    const renderHoverTooltipData = () => {
        const { hoverAccountMenuNav } = preferences;
        const totalCerts = _get(accounts, 'userProfile.totalCerts', 0);
        isListClicked && setIsListClicked(false);
        let accountIconWidth = '80px';
        if (props.accountIconRef) {
            const position =
                props.accountIconRef.getBoundingClientRect &&
                props.accountIconRef.getBoundingClientRect();
            accountIconWidth = position?.width;
        }
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        return (
            <div
                className={cx('listWrapper', isUserLoggedIn && 'accountLoggedIn')}
                onClick={(event) => event.stopPropagation()}
            >
                {!isUserLoggedIn && (
                    <>
                        <Link
                            type="Button"
                            onClick={() => triggerSignInSlider(false, null, false)}
                            data-automation-id="account-signin"
                            buttonType="Primary"
                            size="Md"
                            className={cx('hoverButton')}
                        >
                            Sign In
                        </Link>
                        <Link
                            type="Button"
                            buttonType="Secondary"
                            data-automation-id="account-create-account"
                            onClick={() => triggerSignInSlider(true, null, false)}
                            size="Md"
                            className={cx('hoverButton', 'createAccountButton')}
                        >
                            Create Account
                        </Link>
                    </>
                )}
                {
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        className={cx('hoverAction')}
                        onClick={() => headerAccountMenuClickHandler()}
                        style={{ width: accountIconWidth }}
                    />
                }
                {isUserLoggedIn && renderRewardsInfoInAccountHover()}
                {isCamNavHovered && (
                    <CAMNavWrapper
                        navConfig={hoverAccountMenuNav}
                        classes={{
                            navContainer: 'headerAccountMenu',
                            hoverContainer: 'hoverAccountWrapper',
                        }}
                        highlightActiveItem={false}
                        navListCallBack={(value) => setIsListClicked(value)}
                        rewardsSpend={totalCerts}
                    />
                )}
            </div>
        );
    };

    const showRewardsInfoInHeader = (accountState) => {
        const isEligibleForRewardsPilot =
            (Cookies.load('eligibleForRewardsPilot') && featureFlags.enableRewardsPilotProgram) ||
            false;
        const { firstName = '', userState = '0' } = accounts.userProfile || {};
        const isRegisteredUser = userState === '1' || userState === '2';
        const getRewardDetailsFromLocalStorage =
            TokenProvider.get(REWARD_PERSONALIZATION_DP_REWARDS_DETAILS) || {};
        const {
            points: { pointsThreshold = 200, earnedPoints = 0 } = {},
            certificates: { numberOfCerts = 0, totalCertsValue = 0 } = {},
        } = getRewardDetailsFromLocalStorage;
        const {
            earnedPointThreshold = 50,
            earnedPointThresholdPercentage = 0.25,
        } = rewardsHoverInfoMessagesTexts;
        const calculatedEarnedThreshold = isEligibleForRewardsPilot
            ? pointsThreshold * earnedPointThresholdPercentage
            : earnedPointThreshold;
        const handleRewardsInfo =
            // eslint-disable-next-line no-nested-ternary
            numberOfCerts === 0 && totalCertsValue === 0
                ? earnedPoints >= calculatedEarnedThreshold
                    ? `${earnedPoints} / ${pointsThreshold} ${REWARD_PERSONALIZATION_POINTS}`
                    : REWARD_PERSONALIZATION_REWARDS_MEMBER
                : `$${totalCertsValue} ${REWARD_PERSONALIZATION_REWARD_AVAILABLE}`;
        const userFirstName = startCase(toLower(firstName));
        return (
            <div className={dt(['flex', 'flex-col', 'text-left'])}>
                <p
                    data-automation-id="acc-user-name"
                    data-qm-block="true"
                    className={dt(['font-open-sans-semibold', 'text-small', 'text-black'])}
                >
                    {isRegisteredUser ? userFirstName : REWARD_PERSONALIZATION_MY_ACCOUNT}
                </p>
                <p
                    data-automation-id="acc-info-state"
                    className={dt(['text-xsmall', 'text-black', 'h-4'])}
                >
                    {isRegisteredUser ? handleRewardsInfo : accountState}
                </p>
            </div>
        );
    };

    /* Render account icon for signin and my account */
    const renderAccountIcon = (accountState) => {
        const showAccountIcon = dt(['flex', 'flex-row', 'pointer-events-none']);
        return (
            <div
                className={cx('svg-icon', showAccountIcon)}
                data-automation-id="header_account_block"
            >
                <Icon
                    iconType="svg"
                    automationId="account-icon"
                    className={`${cx('svg-icon')} ${dt(['pr-2'])}`}
                    width="36px"
                    height="36px"
                    viewBox="0 0 36 36"
                    pathClassName={cx('accountFillColor')}
                >
                    <Account />
                </Icon>
                {showWelcomeToolTip && (
                    <div className={cx('welcomeToolTip')}>
                        <span
                            data-automation-id="user-name-cached"
                            className={cx('welcomeToolTipText')}
                        >
                            You're Signed Out
                        </span>
                    </div>
                )}
                {!deviceType.isMobile ? (
                    showRewardsInfoInHeader(accountState)
                ) : (
                    <p data-automation-id="acc-info-state" className={cx('menuText')}>
                        {accountState}
                    </p>
                )}
            </div>
        );
    };

    /* Render account information like  user name and myaccount */
    const renderAccountData = (accountState = config.accountInfo.signInLabel) => {
        return (
            <>
                <ThrottleFeatureRender
                    featurePath="enableHeaderHoverMenu"
                    headerName="X-ENABLE-HEADER-HOVER-MENU"
                    cookieName="DP_ENABLE_HEADER_HOVER_MENU"
                >
                    {(isEnabled) => (
                        <>
                            {isEnabled ? (
                                <YodaTooltip
                                    tooltipContentClass={cx('accountTooltipContent', {
                                        newPersonalisedAccount: true,
                                    })}
                                    tooltipBodyClassName={cx(
                                        'accountHoverTooltip',
                                        isUserLoggedIn && 'loggedIn'
                                    )}
                                    renderTooltipContents={renderHoverTooltipData()}
                                    triggerEventName="mouseover"
                                    stayOnContentHover
                                    isListClicked={isListClicked}
                                    isAccountHover
                                    callBackFun={(isOpen) => {
                                        if (isOpen) {
                                            setIsCamNavHovered(isOpen);
                                        }
                                    }}
                                >
                                    {renderAccountIcon(accountState)}
                                </YodaTooltip>
                            ) : (
                                renderAccountIcon(accountState)
                            )}
                        </>
                    )}
                </ThrottleFeatureRender>
                {showsignInReminder && !isUserLoggedIn && (
                    <div
                        data-automation-id="default-account-username"
                        className={cx('welcomeToolTip')}
                    >
                        <span
                            data-automation-id="user-name-cached"
                            className={cx('welcomeToolTipText', 'welcomeGuestToolTip')}
                        >
                            <span className={cx('startEarningRewards')}>
                                Start Earning Rewards!
                            </span>
                            <>
                                <Link
                                    type="Button"
                                    onClick={() => triggerSignInSlider(false, null, false)}
                                    automationId="account-signin"
                                    buttonType="Primary"
                                    size="Mini"
                                    className={cx('sigInButton', 'welcomeSignInBtn')}
                                >
                                    Sign In
                                </Link>
                                <Link
                                    type="button"
                                    data-automation-id="acc-signup-register-btn"
                                    onClick={() => triggerSignInSlider(true, null, false)}
                                >
                                    <a
                                        data-automation-id="acc-signup-register-anchor"
                                        className={cx('registerLink')}
                                    >
                                        {' '}
                                        {'create account'}{' '}
                                    </a>
                                </Link>
                            </>
                        </span>
                    </div>
                )}
            </>
        );
    };

    const renderAccoundInformation = () => {
        if (isUserLoggedIn) {
            const myAccountLabel =
                _get(accounts, 'accounts.userProfile.firstName', '') ||
                config.accountInfo.myAccountLabel;
            return renderAccountData(myAccountLabel);
        }
        return renderAccountData();
    };

    const getAccountDetails = () => (
        <div className={styles.accountMenu} data-automation-id="account-tooltip">
            {renderAccoundInformation()}
        </div>
    );

    const prepareMenuList = (attribute = 'guest') => {
        const hamburgerPerf = preferences.hamburger || {};
        const desktopHamburgerPerf = _get(preferences, 'desktop.hamburger', {});
        let accountMenuList = hamburgerPerf[attribute];
        if (deviceType.isDesktop) {
            const desktopAccountMenuList = desktopHamburgerPerf[attribute] || [];
            const destMenu = _keyBy(accountMenuList, 'name');
            const srcMenu = _keyBy(desktopAccountMenuList, 'name');
            accountMenuList = _values(_merge({}, destMenu, srcMenu));
        }
        return filter(accountMenuList, (item) => item.isDisplayRequiredInAccountMenu);
    };
    /* istanbul ignore next */
    const menuListClickOption = (nextUrl) => {
        if (!__SERVER__) {
            if (featureFlags.enableSignInSlider) {
                if (featureFlags.enableLogoutSlider && nextUrl === '/sessiontimeout') {
                    return triggerSignInSlider(false, undefined, true);
                }
                if (nextUrl === '/sessiontimeout') {
                    return nextUrl;
                }
                if (LocalStorage.getData('DP_USER_STATE') === '1') {
                    return true; // redirect through Link component
                }
                return triggerSignInSlider(false, nextUrl);
            }
            return nextUrl; // redirect through Link component
        }
        return false; // tell Link component not to redirect
    };
    /* istanbul ignore next */
    const renderMenuList = (item) => {
        const rewardsCount = accounts?.userProfile?.rewardsStatus
            ? accounts.userProfile.totalCerts
            : 0;
        const linksForLabels = item.link;
        const sliderNeeded = item.isSliderNeeded || false;

        const totalCerts =
            accounts?.userProfile?.totalCerts !== '0' ? accounts.userProfile.totalCerts : '';

        if (sliderNeeded) {
            return (
                <Link
                    type="button"
                    className={cx('links')}
                    onClick={() => menuListClickOption(linksForLabels)}
                >
                    {item.name}
                    {totalCerts && item.displayCount === 'rewards' && (
                        <span className={cx('rewardscount')}>{`(${rewardsCount})`}</span>
                    )}
                </Link>
            );
        }

        return (
            <Link href={linksForLabels} className={cx('links')} data-automation-id="item-name">
                {item.name}
                {totalCerts && item.displayCount === 'rewards' && (
                    <span
                        data-automation-id="rewards-count"
                        className={cx('rewardscount')}
                    >{`(${rewardsCount})`}</span>
                )}
            </Link>
        );
    };

    const renderAccountTooltipData = () => {
        const totalCerts = _get(accounts, 'userProfile.totalCerts', 0);

        let source;
        let styleClass;
        let showButtonANdLink = false;
        const rewardsStatus = _get(accounts, 'userProfile.rewardsStatus');
        const rewardsCount = rewardsStatus ? totalCerts : 0;

        if (isUserLoggedIn) {
            source = prepareMenuList('signedin');
            styleClass = totalCerts || totalCerts === 0 ? 'signedInWithCerts' : 'signedInUser';
        } else {
            source = prepareMenuList('guest');
            styleClass = 'signedOutUser';
            showButtonANdLink = true;
        }
        return (
            <div data-automation-id="headerAccountTooltip" id="headerAccount">
                {showButtonANdLink ? (
                    <>
                        <Link
                            type="Button"
                            onClick={() => triggerSignInSlider(false, null, false)}
                            automationId="account-signin"
                            buttonType="Primary"
                            size="Lg"
                            className={cx('sigInButton')}
                        >
                            Sign In
                        </Link>
                        <span data-automation-id="acc-signup-text" className={cx('newCustomer')}>
                            {'New Customer? '}
                        </span>
                        <Link
                            type="button"
                            data-automation-id="acc-signup-register-btn"
                            onClick={() => triggerSignInSlider(true, null, false)}
                        >
                            <a
                                data-automation-id="acc-signup-register-anchor"
                                className={cx('registerLink')}
                            >
                                {' '}
                                {'Register Here'}{' '}
                            </a>
                        </Link>
                    </>
                ) : null}
                <List
                    datasource={source}
                    direction="Vertical"
                    listBodyClass={cx('list-class')}
                    listStyleClass={cx('list-ul-class')}
                    itemStyleClass={cx('list-item-class', styleClass)}
                    childRenderer={renderMenuList}
                    spacing="None"
                    automationId="at-error-helpfullinks-renderer"
                />
                {totalCerts ? (
                    <Button
                        type="button"
                        automationId="rewards-redeem"
                        buttonType="Primary"
                        size="Lg"
                        className={cx('redeemText')}
                    >
                        <span
                            data-automation-id="acc-info-rewards-block"
                            className={cx('redeemCertsWrapper')}
                        >
                            <span data-automation-id="rewardsAmount" className={cx('dollerValue')}>
                                $
                            </span>
                            <span data-automation-id="rewards-count">{rewardsCount}</span>
                        </span>
                        Redeem Your Rewards
                    </Button>
                ) : null}
            </div>
        );
    };

    const renderTooltipData = () => {
        return props.showLoader ? (
            <Loader automationId="account-loader" />
        ) : (
            renderAccountTooltipData()
        );
    };

    useEffect(() => {
        /* istanbul ignore next */
        if (showsignInReminder) {
            const toolTipTimeout = _get(preferences, 'signInSliderWelcomeMessageTimeOut', 3000);
            setTimeout(() => {
                setShouldShowsignInReminder(false);
            }, toolTipTimeout);
        }
    }, []);

    let item = null;
    if (__SERVER__) {
        item = getAccountDetails();
    } else {
        item = headerLoaded && (
            <ThrottleFeatureRender
                featurePath="enableHeaderAccountMenuSlider"
                headerName="X-HEADER-ACCOUNT-MENU-SLIDER"
                cookieName="DP_HEADER_ACCOUNT_MENU_SLIDER"
            >
                {
                    /* istanbul ignore next */
                    (isEnabled) => (
                        <>
                            {isEnabled && (
                                // Div is for IE11 changes for event bubbling
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div onClick={() => headerAccountMenuClickHandler()}>
                                    {getAccountDetails()}
                                </div>
                            )}
                            {!isEnabled && (
                                <YodaTooltipLazy
                                    tooltipContentClass={styleTooltip}
                                    tooltipBodyClassName={cx('headerTooltipWrapper')}
                                    renderTooltipContents={renderTooltipData()}
                                    triggerEventName="click"
                                    direction="Bottomright"
                                    tooltipPlacement="Right"
                                >
                                    {getAccountDetails()}
                                </YodaTooltipLazy>
                            )}
                        </>
                    )
                }
            </ThrottleFeatureRender>
        );
    }
    return item;
};

RenderHeaderAccount.defaultProps = {
    accountIconRef: {},
    isUserLoggedIn: false,
    pageName: 'HOME',
    showLoader: false,
};

RenderHeaderAccount.propTypes = {
    accountIconRef: PropTypes.object,
    isUserLoggedIn: PropTypes.bool,
    pageName: PropTypes.string.isRequired,
    showLoader: PropTypes.bool,
};

export default RenderHeaderAccount;

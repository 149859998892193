/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { sha256 } from 'js-sha256';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import LocalStorage, {
    SessionStorage,
} from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import {
    detectisIE,
    getURLParameterBykey,
    updateQueryStringParameter,
} from 'yoda-core-components/lib/helpers/Utils/Utils';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import List from 'yoda-core-components/lib/components/List/List';
import Warning from 'yoda-core-components/lib/assets/svg/Warning.svg';
import CartLarge from 'yoda-core-components/lib/assets/svg/CartLarge.svg';
import CartLine from 'yoda-core-components/lib/assets/svg/CartLine.svg';
import {
    getUserDetails,
    accessTimerStart,
    accessTimerAuthCheck,
} from '../../actions/AccountAction';
import storeNavigationActions from '../../actions/DepartmentVisualNavigationAction';
import { displayHamburgerSlider, getIrisCategories } from '../../actions/HamburgerAction';
import itemCountAction from '../../actions/ItemCountAction';
import KeepMeLoggedInAction from '../../actions/KeepMeLoggedInAction';
import {
    loadUserStoreLocationInfo,
    updateStoreOnCartAction,
    updateUserStoreLocationInfo,
} from '../../actions/LocationServiceAction';
import storeActions from '../../actions/SetMyDefaultStoreAction';
import {
    findStores,
    selectStore,
    setFindStoreSliderProps,
    openSlidePanel,
} from '../../actions/FindStoresAction';
import {
    triggerBopisDefaultStoreChange,
    triggerBopisPickupStoreChange,
} from '../../actions/AnalyticsAction';
import { showLazy } from '../../lazy/actions/LazyAction';
import vendorAction from '../../actions/OtherVendorAction';
import GalleryRerenderAction from '../../actions/GalleryRerenderAction';
import { selectShouldRender } from '../../selectors/ContextSelector';
import { selectKeepMeLoggedInThrottleFF } from '../../selectors/KeepMeLoggedInSelector';
import iovationLoader from '../../helpers/IovationLoader/IovationLoader';
import { isGalleryPage, isCartPage } from '../../helpers/Utils/pageType';
import isCSSFeatureSupported from '../../helpers/Utils/features';
import { setNormalizedEmailHash, getHeaderTheme } from './headerV3Utils';
import {
    FIND_STORES_PAGE_SIZE,
    LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
} from '../../common/Constants';
import StickyTimer from '../StickyTimer/StickyTimer';
import TopMiniNav from '../TopMiniNav/TopMiniNav';
import ThirdPartyServiceButton from './ThirdPartyServiceButton';
import FetchRegion from '../FetchRegion/FetchRegion';
import MobileBotHamburger from '../MobileBotHamburger/MobileBotHamburger';
import ProductSearch from '../ProductSearch/ProductSearch';
import HamburgerPlaceHolder from './HamburgerPlaceHolder';
import RenderLogo from './RenderLogo';
import RenderStoreInfo from './RenderStoreInfo';
import RenderHeaderAccount from './RenderHeaderAccount';
import CartWrapper from './CartWrapper';
import Link from '../Link/Link';
import ExitPosLazy from '../ExitPos/ExitPosChunksLazy';
import SecondaryNavigationPanel from './SecondaryNavigationPanel';
import { fetchVizNavIrisDepartmentData } from './GetNavigationData';
import * as styles from './Header.css';

const cx = classNames.bind(styles);

const SCROLL_THRESHOLD = 25;
const TIME_THRESHOLD = 300;
const PRIMARY_HEADER_HEIGHT = 56;
let scrollTicking = false;
let scrollPreviousValue = 0;

const irishambergurMenu = (state) => state.irishambergurMenu;
const irishambergurMenuCreateSelector = createSelector([irishambergurMenu], (data) =>
    fetchVizNavIrisDepartmentData(data)
);

const HeaderV3 = (props) => {
    console.log('Header V3 Loaded');
    let timestamp = new Date();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [headerScroll, setHeaderScroll] = useState('mainHeaderScroll');
    const [stickyCustomClass, setStickyCustomClass] = useState(null);
    const [mounted, setMounted] = useState(false);

    const prevIsUserLoggedInRef = useRef({ isUserLoggedIn });
    let accountIconRef = useRef();
    const dispatch = useDispatch();

    const preferences = useSelector((state) => state?.context?.preferences || {});
    const deviceType = useSelector((state) => state?.context?.deviceType || {});
    const featureFlags = useSelector((state) => state?.context?.featureFlags || {});
    const cartCount = useSelector((state) => state.bagItemCount || 0);
    const isNative = useSelector((state) => state.context?.isNative || false);
    const enableKeepMeLoggedIn = useSelector((state) => selectKeepMeLoggedInThrottleFF(state));
    const accounts = useSelector(
        (state) =>
            state.accounts || {
                userProfile: {
                    firstName: null,
                    accountId: null,
                    rewardsStatus: null,
                    userState: null,
                    totalCerts: null,
                },
            }
    );
    const locationServiceStoreInfo = useSelector((state) => state.locationServiceReducer) || {};
    const isFragment = useSelector((state) => state.context?.isFragment || false);
    const shouldRender = useSelector((state) => selectShouldRender(state)) || true;
    const showOverlay = useSelector((state) => state.showOverlay);
    const showBgWhite = useSelector((state) => state.showBgWhite || false);
    const productSearchFocus = useSelector((state) => state.productSearchFocus || '');
    const HamburgerSlider = useSelector((state) => state.HamburgerSlider || {});
    const pdpProductOffering = useSelector((state) => state.productOffering) || {};
    const findStoresDetails = useSelector((state) => state.findStoresDetails || {});
    const activeCartConfig = useSelector((state) =>
        _get(state.context, 'preferences.activeCartReminderConfig', {})
    );
    const toggleWiggleCart = useSelector((state) => state.toggleWiggleCart || {}, shallowEqual);
    const hambergurMenu = useSelector(irishambergurMenuCreateSelector);
    const galleryRerender = useSelector((state) => state.rerender);

    const enableActiveCart =
        !__SERVER__ && featureFlags.enableActiveCartReminder && !isEmpty(activeCartConfig);

    let primaryLinks = { cart: '' };
    if (preferences?.primaryLinks) {
        const primaryLinksObj = preferences?.primaryLinks || {};
        primaryLinks = deviceType.isDesktop ? primaryLinksObj.desktop : primaryLinksObj.device;
    }

    const getPreferredStore = () => {
        let returnValue = '';
        const userPreferredStoreCookie = Cookies.load(
            LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
            true
        );
        returnValue = !isEmpty(userPreferredStoreCookie) ? locationServiceStoreInfo : {};

        return returnValue;
    };

    const userPreferredStore = getPreferredStore();
    let pageUrl = '';
    /* istanbul ignore else */
    if (!__SERVER__) {
        pageUrl = window && window.location.href;
    }
    const urlStoreId = getURLParameterBykey('storeIds', pageUrl);
    const locationServiceStoreId = _get(userPreferredStore, 'storeId', null);
    const disableTopMini = featureFlags.disableAccessViewFromHeader && !featureFlags.promoBanner;
    const { hideAccountSection = false, hideStoresBlock = false } =
        preferences.headerConfigs?.flags || {};
    const cartIconComponent = cartCount > 99 ? <CartLarge /> : <CartLine />;
    const enableExitPosClient = !__SERVER__ && featureFlags.enableExitPos;
    const { hideVisNavSlider = true } = featureFlags;

    const getStoreDetails = (shouldRefreshStore = false) => {
        if (featureFlags.enableLocationService) {
            dispatch(loadUserStoreLocationInfo(shouldRefreshStore));
        }
    };

    const hydrateOnMount = () => {
        const { pageName } = props;
        const isServer = __SERVER__;
        /* istanbul ignore next */
        if (!isServer) {
            dispatch(getUserDetails());
            if (!preferences.storeCallNotNeededOnPageLoad) {
                dispatch(storeActions.setMyDefaultStore());
            }
            setShowLoader(true);
            dispatch(itemCountAction.updateItemCountAction());
            // Client-side rendering for IRIS Global Nav APIs
            const shouldFetchNav =
                deviceType.isDesktop && featureFlags && !featureFlags.disableVisualNavSSR;
            if (shouldFetchNav) {
                dispatch(
                    storeNavigationActions.getIrisGlobalNavigationAction({ pageType: pageName })
                );
            }

            if (featureFlags && featureFlags.enableHybridHamburger) {
                // Fetch department list for hybrid hamburger
                dispatch(getIrisCategories({ pageType: pageName }));
            }
        } else if (
            !featureFlags.disableHeaderSSR ||
            (deviceType.isDesktop && deviceType.isBot && featureFlags.enableRenderBotDept)
        ) {
            // Server-side rendering for IRIS Global Nav APIs, ONLY FOR BOT
            dispatch(storeNavigationActions.getIrisGlobalNavigationAction({ pageType: pageName }));
        }
        // Update the store details if the cookie is available.
        getStoreDetails();
    };

    const authCheckForExpiry = () => {
        /* istanbul ignore if */
        if (
            featureFlags?.recognisedStatePageRefresh &&
            TokenProvider.get('DP_USER_STATE') === '1'
        ) {
            if (TokenProvider.get('DP_FULL_ACCESS_EXPIRED') === 'true') {
                dispatch(accessTimerAuthCheck());
            }
            setTimeout(() => {
                authCheckForExpiry();
            }, 20 * 1000);
        }
    };
    /* istanbul ignore next */
    const isScrollThresholdMet = (scrollY = 0) => {
        const scrollDifference = Math.abs(scrollPreviousValue - scrollY);
        const timestampDifference = new Date() - timestamp;
        return scrollDifference > SCROLL_THRESHOLD && timestampDifference > TIME_THRESHOLD;
    };
    /* istanbul ignore next */
    const scrollHandler = ({ scrollY, headerScrollVal }) => {
        if (galleryRerender) {
            let scrollClass = null;
            if (deviceType.isMobile) {
                scrollClass = 'headerScrollUp';
            }
            setHeaderScroll(scrollClass);
            scrollPreviousValue = scrollY;
            timestamp = +new Date();
            dispatch(GalleryRerenderAction.galleryRerenderAction(false));
            return;
        }

        if (headerScrollVal !== headerScroll && isScrollThresholdMet(scrollY)) {
            setHeaderScroll(headerScrollVal);
            scrollPreviousValue = scrollY;
            timestamp = +new Date();
        }
    };
    /* istanbul ignore next */
    const onSrollTriggerEvents = () => {
        const { isDesktop, isMobile } = deviceType;

        const enableOverflowFilters = featureFlags?.enableOverflowFilters || false;

        // Sticky Filter for PLP/SRP
        const target = document.getElementById('productlistheight');
        const containerWidthIris = document.getElementById('containerWidthIris');
        let isTopInView = false;
        if (containerWidthIris && target && enableOverflowFilters && isDesktop) {
            const rect = target.getBoundingClientRect();
            const containerHeight = containerWidthIris.getBoundingClientRect();
            isTopInView = rect.top <= containerHeight.height && rect.top >= 0;
        }
        if (enableOverflowFilters && isDesktop && isTopInView) {
            setStickyCustomClass('stickyRemoveTopPadding');
        } else if (enableOverflowFilters && isDesktop) {
            setStickyCustomClass('stickyAddTopPadding');
        } else {
            setStickyCustomClass(null);
        }
        // Sticky Filter for PLP/SRP

        if (!scrollTicking) {
            window.requestAnimationFrame(() => {
                scrollTicking = true;
                const scrollY = window.scrollY || window.pageYOffset;
                let headerScrollVal = headerScroll;
                if (
                    isMobile
                        ? scrollY > scrollPreviousValue &&
                          !(
                              scrollPreviousValue === 0 &&
                              scrollY > 0 &&
                              headerScrollVal !== 'headerScrollDown'
                          )
                        : scrollY > scrollPreviousValue
                ) {
                    if (scrollY > PRIMARY_HEADER_HEIGHT) {
                        if (!isDesktop) {
                            headerScrollVal = 'headerScrollDown';
                        } else {
                            headerScrollVal = null;
                        }
                    }
                    scrollHandler({ scrollY, headerScrollVal });
                } else {
                    if (isDesktop && scrollY < PRIMARY_HEADER_HEIGHT) {
                        scrollHandler({ scrollY, headerScrollVal: null });
                    } else if (isMobile && scrollY === 0) {
                        scrollHandler({ scrollY, headerScrollVal });
                    } else {
                        headerScrollVal = 'headerScrollUp';
                        isMobile && scrollY > 100 && scrollHandler({ scrollY, headerScrollVal });
                    }
                    headerScrollVal = 'headerScrollUp';
                    !isMobile && scrollY > 100 && scrollHandler({ scrollY, headerScrollVal });
                }
                scrollPreviousValue = scrollY;
                scrollTicking = false;
            });
        }
    };

    /* SSR Render */
    if (!mounted && __SERVER__) {
        hydrateOnMount();
    }

    /* istanbul ignore next */
    useEffect(() => {
        setMounted(true);
        hydrateOnMount();
        const enableRewardsWebview = featureFlags.enableRewardsWebview || true;
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;

        dispatch(showLazy('headerLoad'));
        if (!isNativeRewardsEnabled) {
            dispatch(accessTimerStart());
            authCheckForExpiry();
        }
        const { enableIovationEagerLoading = true, disableStickyHeader } = featureFlags;
        const {
            devicefingerPrintConfig: iovationConfig,
            deviceFingerPrintIovation: iovationSrc,
        } = preferences;
        const { onIovationScriptLoadError, onIovationScriptLoadSuccess } = KeepMeLoggedInAction;
        if (enableKeepMeLoggedIn && !enableIovationEagerLoading) {
            iovationLoader(
                iovationSrc,
                iovationConfig,
                onIovationScriptLoadError,
                onIovationScriptLoadSuccess
            );
        }
        window.handleVendorAction = (actionType, payload, callback) => {
            if (actionType === 'apply_coupon' && payload.couponCode) {
                dispatch(
                    vendorAction.applyOtherCoupon(
                        {
                            code: payload.couponCode,
                            serialNumber: payload.serialCode,
                        },
                        callback
                    )
                );
            }
        };
        if (
            !disableStickyHeader &&
            isCSSFeatureSupported(
                '(position: sticky) or (position: -webkit-sticky) or (position: -ms-sticky)'
            ) &&
            !deviceType.isTablet
        ) {
            window.addEventListener('scroll', onSrollTriggerEvents);
        }
        return () => {
            window.removeEventListener('scroll', onSrollTriggerEvents);
        };
    }, []); // Empty dependency array ensures this effect runs only once

    /* istanbul ignore next */
    useEffect(() => {
        const isLoggedIn = (_get(accounts, 'userProfile.userState', '0') || '0') !== '0';
        setIsUserLoggedIn(isLoggedIn);
        if (accounts.userProfile) {
            setShowLoader(false);
        } else {
            setShowLoader(true);
        }
    }, [accounts]);
    /* istanbul ignore next */
    useEffect(() => {
        const { address = '' } = userPreferredStore;
        if (!isEmpty(address)) {
            dispatch(
                updateUserStoreLocationInfo({
                    curbsidePickupIndicator: userPreferredStore.curbsidePickupIndicator || false,
                    distance: userPreferredStore.distance || '',
                    id: userPreferredStore.storeId || '',
                    inputZipCode: userPreferredStore.inputZipCode || '',
                    name: userPreferredStore.storeName || '',
                    phone: userPreferredStore.phone || '',
                    zip: userPreferredStore.zipcode || '',
                    street: userPreferredStore.street || '',
                    city: userPreferredStore.city || '',
                    state: userPreferredStore.state || '',
                    timings: userPreferredStore.timings || '',
                    timingsOverrideMessage: userPreferredStore.timingsOverrideMessage || '',
                })
            );
        }
    }, [userPreferredStore.address]);
    /* istanbul ignore next */
    useEffect(() => {
        // To update store details in cart when a user gets logged in
        if (!isEmpty(accounts) && !prevIsUserLoggedInRef && isUserLoggedIn) {
            if (locationServiceStoreId) {
                dispatch(updateStoreOnCartAction(locationServiceStoreId));
            } else {
                getStoreDetails(true);
            }
            try {
                const { enableNormalizedEmailHash } = featureFlags;
                if (enableNormalizedEmailHash) {
                    if (window && window.jcpDLjcp) {
                        const existingProfile = _get(window.jcpDLjcp, 'profile', {});
                        const existingProfileArray = Object.keys(existingProfile);
                        const currEmail = LocalStorage.getData('DP_USER_EMAIL');
                        const trimmedCurrEmail = currEmail ? currEmail.trim().toLowerCase() : '';
                        const currEmailHash = sha256(trimmedCurrEmail);
                        if (existingProfileArray.includes('normalizedEmailHash')) {
                            const existingEmailHash = existingProfile.normalizedEmailHash;
                            if (existingEmailHash !== currEmailHash) {
                                setNormalizedEmailHash(existingProfile, currEmailHash);
                            }
                        } else {
                            setNormalizedEmailHash(existingProfile, currEmailHash);
                        }
                    }
                }
            } catch (e) {
                console.error('error when saving normalized email hash', e);
            }
        }
        prevIsUserLoggedInRef.current = { isUserLoggedIn };
    }, [accounts, isUserLoggedIn]);
    /* istanbul ignore next */
    useEffect(() => {
        if (pageUrl && isGalleryPage(pageUrl)) {
            if (
                !isEmpty(locationServiceStoreId) &&
                !isEmpty(urlStoreId) &&
                urlStoreId !== locationServiceStoreId
            ) {
                const isSDPUEnabled = getURLParameterBykey('store_availability', pageUrl);

                if (
                    !__SERVER__ &&
                    !document.getElementById('storeGalleryBopisFilter') &&
                    isSDPUEnabled
                ) {
                    const updatedUrl = new URL(pageUrl);
                    updatedUrl.searchParams.set('storeIds', locationServiceStoreId);
                    history.pushState({}, '', updatedUrl);
                }
            }
        }
    }, [urlStoreId, locationServiceStoreId]);

    useEffect(() => {
        const { storeName = '' } = userPreferredStore;
        const shouldLoadLocationInfo = !storeName && isFragment;
        // loadUserStoreLocationInfo to be called on syndicate sites as the cookie value is stored by browser a bit delayed.
        shouldLoadLocationInfo && loadUserStoreLocationInfo();
    }, []);

    const renderStickyTimer = () => {
        let stickyTimerHtml = false;
        const isIE = detectisIE();
        if (isIE) {
            stickyTimerHtml = featureFlags.enableStickyTimer &&
                featureFlags.enableStickyTimerForIE && (
                    <StickyTimer renderFromHeader mountOnServer />
                );
        } else {
            stickyTimerHtml = featureFlags.enableStickyTimer && (
                <StickyTimer renderFromHeader mountOnServer />
            );
        }
        return stickyTimerHtml;
    };

    const triggerDisplayHamburgerSlider = (menuOpenStatus) => {
        dispatch(displayHamburgerSlider(menuOpenStatus));
    };

    const triggerStoreSelectionSlider = (e) => {
        e?.stopPropagation && e.stopPropagation();

        const { checkoutProps: { findStores: { selectActionCallback } = {} } = {} } = props;
        const { inputZipCode = '' } = getPreferredStore();
        const { actualSkuId = '', promise: { skuDeliveryOptions } = {} } = pdpProductOffering;
        /* istanbul ignore next */
        const onSliderSelectStore = (sliderCallBack = {}) => {
            if (isCartPage(pageUrl)) {
                dispatch(selectStore({ ...sliderCallBack, selectActionCallback }));
            }
            if (pageUrl && isGalleryPage(pageUrl)) {
                const isSDPUEnabled = getURLParameterBykey('store_availability', pageUrl);
                if (isSDPUEnabled) {
                    const { id = '' } = sliderCallBack?.storeDetails || {};
                    window.location.href = updateQueryStringParameter(pageUrl, 'storeIds', id);
                }
            }
            if (actualSkuId) {
                dispatch(selectStore({ ...sliderCallBack, fromMiniPDP: false }));
            }
            const keyValue = SessionStorage.getData('setBopisDefaultStore', true);
            if (isEmpty(keyValue)) {
                dispatch(triggerBopisDefaultStoreChange());
                SessionStorage.setData('setBopisDefaultStore', true, true);
            } else dispatch(triggerBopisPickupStoreChange());
        };
        const { zipCode = '' } = findStoresDetails;
        const searchZip = inputZipCode || zipCode;
        const parentSkuId = skuDeliveryOptions?.[0]?.skuId;
        /* istanbul ignore else */
        if (searchZip) {
            const { defaultRadius = 25 } = preferences;
            const payload = {
                zipCode: searchZip,
                miles: defaultRadius,
                pageSize: FIND_STORES_PAGE_SIZE, // number of stores to request per request
                showAvailable: false,
                skus: parentSkuId || actualSkuId || '',
            };
            dispatch(findStores(payload));
        }
        // Binding action creators to dispatch
        const boundActionCreators = bindActionCreators({ findStores, openSlidePanel }, dispatch);
        // Accessing the bound action creator
        const {
            findStores: findStoresAction,
            openSlidePanel: openSlidePanelAction,
        } = boundActionCreators;
        dispatch(
            setFindStoreSliderProps({
                findStoresAction,
                enableSlimFindStores: true,
                enableProductCardImage: true,
                hideContentScroll: true,
                isMounted: openSlidePanelAction,
                selectStoreAction: onSliderSelectStore,
                skuSelected: actualSkuId || '',
            })
        );
        dispatch(openSlidePanel());
    };

    const renderHamburger = () => {
        const { pageName = 'HOME' } = props;
        const { enableHamburgerMenuLabel = false, enableRenderBotDept = false } = featureFlags;
        const { isDesktop, isBot = false } = deviceType;
        const botHamburger =
            isBot && enableRenderBotDept ? (
                <MobileBotHamburger pageName={pageName} />
            ) : (
                <HamburgerPlaceHolder
                    enableHamburgerMenuLabel={enableHamburgerMenuLabel}
                    isBot={isBot}
                    enableRenderBotDept={enableRenderBotDept}
                    HamburgerSlider={HamburgerSlider}
                    displayHamburgerSlider={triggerDisplayHamburgerSlider}
                    triggerStoreSelectionSlider={triggerStoreSelectionSlider}
                    getPreferredStore={getPreferredStore}
                />
            );
        return !isDesktop ? botHamburger : null;
    };

    const searchSelectAnimationRemove = () => {
        if (headerScroll !== 'headerScrollUp') {
            setHeaderScroll('headerScrollUp');
        }
    };

    const validateHomePage = () => {
        if (!__SERVER__) {
            return window.location.pathname === '/';
        }
        return false;
    };

    const visNavRender = (dataItem) => {
        const { title, image, links, openInNewTab } = dataItem;
        return (
            <div className={cx('headerVisualNavComponent')}>
                <a
                    className={cx('visualNavLink')}
                    href={links}
                    aria-label={title}
                    data-link-href={links}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel="noreferrer"
                >
                    {image && (
                        <div className={cx('imgBlock')}>
                            <img
                                className={cx('vizNavImage')}
                                src={image}
                                alt={title}
                                title={title}
                            />
                        </div>
                    )}
                    <div className={cx('title')}>{title}</div>
                </a>
            </div>
        );
    };

    // Styles
    const headerOverStyle = dt([
        'absolute',
        'hidden',
        'top-0',
        'left-0',
        'bottom-0',
        'right-0',
        'w-full',
        'h-full',
        'bg-black',
        'bg-opacity-60',
        'sm:z-[8]',
    ]);
    const headerMobileClass = deviceType.isMobile ? 'headerMobile' : 'hederNotMobile';
    const headerTabletClass = deviceType.isTablet ? 'headerTablet' : 'headerNotTablet';
    const headerDesktopClass = deviceType.isDesktop ? 'headerDesktop' : 'headerNotDesktop';
    const headerSearchDesktopClass = deviceType.isDesktop
        ? 'headerSearchDesktop'
        : 'headerSearchNotDesktop';
    const headerBotClass = deviceType.isBot ? 'headerBot' : 'headerNotBot';
    const disableStickyHeaderClass = featureFlags.disableStickyHeader ? 'disableStickyHeader' : '';
    const bgWhite = showBgWhite ? 'makeBgWhite' : '';
    const newSiteHeightClass = !featureFlags.promoBanner ? 'noHeight' : '';
    const cartStyleName = cx('bagBlock', 'ctaList', 'cartBag');
    const moreCartStyles = cartCount > 99 && 'moreCart';
    const flyoutMenuDeviceClass = deviceType.isDesktop ? 'menuDesktop' : 'menuNotDesktop';
    const flyoutMenuClass = featureFlags.enableFlyoutMenu ? 'flyoutMenu' : 'secondaryMenu';

    if (!shouldRender) {
        {
            renderStickyTimer();
        }
    }
    return (
        <>
            {/* render Chat button as hidden and ensighten  witll pick dom and do further processing */}
            {!isFragment && <ThirdPartyServiceButton name="chat" />}
            {props.inlineOverlay && showOverlay && (
                <div className={cx('inlineOverlay', props.menuOverlayClass || '')} />
            )}
            <div id="headerOverlay" className={cx('headerOverlay', headerOverStyle)} />
            {/* Header Primary */}
            {props.rewardsErrorObj?.rewardsError && (
                <div className={dt(['bg-warning-border', 'px-6', 'py-3', 'lg:text-center'])}>
                    <div className={dt(['relative'])}>
                        <div
                            className={dt([
                                'smMd:absolute',
                                'smMd:left-0',
                                'smMd:top-0',
                                'lg:inline',
                            ])}
                        >
                            <Icon iconType="svg" width="24" height="24" viewBox="0 2 28 28">
                                <Warning />
                            </Icon>
                        </div>
                        <p className={dt(['text-medium', 'ml-8', 'lg:inline', 'lg:ml-2'])}>
                            {props.rewardsErrorObj?.rewardsErrorText}
                        </p>
                    </div>
                </div>
            )}
            {!disableTopMini && (
                <TopMiniNav
                    deviceType={deviceType}
                    isNewHeader
                    enableSitePromo={featureFlags.promoBanner}
                    disableAccessViewFromHeader={featureFlags.disableAccessViewFromHeader}
                />
            )}
            {!isNative && (
                <header
                    data-automation-id="headerBlock"
                    id={props.headerId || 'header'} // for analytics activity map
                    className={cx(
                        'headerBlock',
                        'newHeader',
                        'tailwindHeader',
                        props.headerCustomClass,
                        headerMobileClass,
                        headerTabletClass,
                        headerDesktopClass,
                        headerSearchDesktopClass,
                        headerBotClass,
                        headerScroll,
                        stickyCustomClass,
                        getHeaderTheme(props.headerType),
                        disableStickyHeaderClass,
                        bgWhite
                    )}
                >
                    <div
                        data-automation-id="mainHeaderBlock"
                        className={cx('mainHeaderBlock', productSearchFocus, newSiteHeightClass)}
                    >
                        <FetchRegion />
                        {/* Header Secondary */}
                        <div
                            data-automation-id="headerMainBlock"
                            className={cx('mainMenu', props.headerMainClass || '')}
                        >
                            <ul className={cx('headerBlockList', 'containerWidth')}>
                                {/* iconTextWrapper class will have to inject only if the menu feature flag enabled */}
                                <li
                                    className={cx(
                                        'headerList',
                                        'menuBlock',
                                        !deviceType.isDesktop && 'iconTextPadding',
                                        featureFlags.enableHamburgerMenuLabel
                                            ? 'iconTextWrapper'
                                            : ''
                                    )}
                                >
                                    <div
                                        id="hamburgerBlock"
                                        data-automation-id="hamburgerBlock"
                                        className={styles.headerNavigation}
                                    >
                                        {renderHamburger()}
                                    </div>
                                </li>
                                {/* logoCenter class will have to inject only if the menu feature flag enabled */}
                                <li
                                    id="logoBlock"
                                    data-automation-id="logoBlock"
                                    className={cx('headerList', 'logoBlock')}
                                >
                                    <div className={cx('logoMainBlock')}>
                                        <RenderLogo logoLink={props.logoLink} />
                                    </div>
                                    {!deviceType.isDesktop && (
                                        <div
                                            id="mobSearchIcon"
                                            data-automation-id="mobSearchIcon"
                                            className={cx('mobSearchIcon', productSearchFocus)}
                                        />
                                    )}
                                </li>
                                <li
                                    id="searchBlock"
                                    data-automation-id="searchBlock"
                                    className={cx(
                                        'headerList',
                                        'searchBlock',
                                        props.searchClass || '',
                                        productSearchFocus
                                    )}
                                >
                                    <ProductSearch
                                        searchSelectAnimationRemove={searchSelectAnimationRemove}
                                        automationId="tablet-search-block"
                                        productSearchContainerCustomClass={cx(
                                            'headerSearchContainerCustomClass'
                                        )}
                                        productSearchIconCustomClass={cx(
                                            'headerSearchIconCustomClass'
                                        )}
                                        productSearchInputCustomClass={cx(
                                            'headerSearchInputCustomClass'
                                        )}
                                        productResetIconCustomClass={cx(
                                            'headerResetIconCustomClass'
                                        )}
                                    />
                                </li>
                                <li
                                    id="ctaBlock"
                                    data-automation-id="ctaBlock"
                                    className={`${cx('headerList', 'ctaBlock')} ${dt([
                                        'float-right',
                                    ])}`}
                                >
                                    {!hideStoresBlock && deviceType.isDesktop ? (
                                        <div
                                            id="findStore"
                                            data-automation-id="findStoreBlock"
                                            className={cx('svg-icon', 'ctaList', 'findStoreBlock')}
                                        >
                                            <RenderStoreInfo
                                                userPreferredStore={userPreferredStore}
                                                triggerStoreSelectionSlider={
                                                    triggerStoreSelectionSlider
                                                }
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* Stores Block Ends Need to put condition for Mobile to Hide */}
                                    {/* Account Block Need to put condition for Mobile to Hide */}
                                    {!hideAccountSection && deviceType.isDesktop ? (
                                        <div
                                            id="accountBlock"
                                            data-automation-id="accountBlock"
                                            className={cx(
                                                'accBlock',
                                                'ctaList',
                                                featureFlags.enableHamburgerMenuLabel
                                                    ? 'noRgtBorder'
                                                    : ''
                                            )}
                                            ref={(node) => {
                                                accountIconRef = node;
                                            }}
                                        >
                                            <RenderHeaderAccount
                                                accountIconRef={accountIconRef}
                                                isUserLoggedIn={isUserLoggedIn}
                                                pageName={props.pageName}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* Account Block Ends Need to put condition for Mobile to Hide */}
                                    {/* Have to do the changes as per Functionality Ends */}
                                    <CartWrapper
                                        cartStyleName={cartStyleName}
                                        enableActiveCart={enableActiveCart}
                                        activeCartConfig={activeCartConfig}
                                        toggleWiggleCart={toggleWiggleCart}
                                    >
                                        <Link
                                            href={primaryLinks.cart}
                                            className={cx('headerIcon', 'bagIcon')}
                                            data-automation-id="bagLink"
                                            title="my cart"
                                            aria-label={`Bag ${cartCount} - items`}
                                            rel="noopener noreferrer"
                                        >
                                            <div className={cx('svg-icon')}>
                                                <Icon
                                                    iconType="svg"
                                                    className={cx('svg-icon')}
                                                    width="40px"
                                                    height="40px"
                                                    viewBox="0 0 40 40"
                                                    pathClassName={cx('cartFillColor')}
                                                >
                                                    {cartIconComponent}
                                                </Icon>
                                            </div>
                                            {cartCount > 0 ? (
                                                <span
                                                    id="orderQuantity"
                                                    data-automation-id="item-bag-count"
                                                    className={cx('cartCount', moreCartStyles)}
                                                >
                                                    {cartCount}
                                                </span>
                                            ) : null}
                                        </Link>
                                    </CartWrapper>
                                    {enableExitPosClient &&
                                    (Cookies.load('x-mode') || Cookies.load('Fusion-Platform')) ? (
                                        <div className={cx('ctaList')}>
                                            <ExitPosLazy />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </li>
                            </ul>
                        </div>
                        {/* Header Secondary Ends */}
                        <div
                            id="flyoutMenuBlock"
                            data-automation-id="flyoutMenuBlock"
                            className={cx(
                                'menuBlock',
                                productSearchFocus,
                                flyoutMenuDeviceClass,
                                flyoutMenuClass
                            )}
                        >
                            {/* For Flyout menu */}
                            {deviceType.isDesktop && (
                                <SecondaryNavigationPanel
                                    deviceType={deviceType}
                                    singleFlyoutSyndicateExperience={!!props.inlineOverlay}
                                />
                            )}
                            {/* For Flyout menu ends */}
                        </div>
                    </div>
                </header>
            )}
            <div
                id="timerVisNavBlock"
                data-automation-id="timerVisNavBlock"
                className={cx('timerVisNavBlock')}
            >
                {/* Placeholder for VisNavBlock */}
                {!deviceType.isDesktop &&
                    !isEmpty(hambergurMenu) &&
                    validateHomePage() &&
                    !hideVisNavSlider && (
                        <List
                            datasource={hambergurMenu}
                            childRenderer={visNavRender}
                            automationId="render-item-content"
                            spacing="None"
                            listMainCustomClass={cx('renderListMainCustomClass')}
                            listStyleClass={cx('renderListStyle')}
                            listBodyClass={cx('renderListBody')}
                            itemStyleClass={cx('renderItemStyle')}
                            direction="Horizontal"
                        />
                    )}
                {/* Placeholder for VisNavBlock Ends */}
                {renderStickyTimer()}
            </div>
        </>
    );
};

HeaderV3.defaultProps = {
    checkoutProps: {},
    headerCustomClass: '',
    headerId: 'header',
    headerMainClass: '',
    headerType: '',
    inlineOverlay: false,
    logoLink: 'on',
    menuOverlayClass: '',
    pageName: 'HOME',
    rewardsErrorObj: {},
    searchClass: '',
};

HeaderV3.propTypes = {
    checkoutProps: PropTypes.object,
    headerCustomClass: PropTypes.string,
    headerId: PropTypes.string,
    headerMainClass: PropTypes.string,
    headerType: PropTypes.string,
    inlineOverlay: PropTypes.bool,
    logoLink: PropTypes.string,
    menuOverlayClass: PropTypes.string,
    pageName: PropTypes.string,
    rewardsErrorObj: PropTypes.object,
    searchClass: PropTypes.string,
};

export default HeaderV3;

export const REWARDS_DESIGN_COOKIE_FLAGNAME = 'DP_REWARDS_REDESIGN';
export const REWARDS_DESIGN_HEADER_FLAGNAME = 'X-REWARDS-REDESIGN';
export const KEEP_ME_LOGGED_IN_COOKIE_FLAGNAME = 'DP_ENABLE_KEEP_ME_LOGGEDIN';
export const KEEP_ME_LOGGED_IN_HEADER_FLAGNAME = 'X-ENABLE-KEEP-ME-LOGGEDIN';
export const PLCC_BANNER_COOKIE_FLAGNAME = 'DP_ENABLE_PAYMENTS_PLCC_BANNER';
export const PLCC_BANNER_HEADER_FLAGNAME = 'X-ENABLE-PAYMENTS-PLCC-BANNER';
export const KEY_FEATURE_CONSTS_MIN_ICONS_COUNT = 2;
export const KEY_FEATURE_CONSTS_MAX_ICONS_COUNT = 9;
export const BARCODE_MODAL_TITLE = 'Use Your Rewards';
export const BARCODE_MODAL_HEADING =
    'To Redeem in-store, present barcode to the cashier. Some <a href="www.jcpenney.com">exclusions</a> may apply.';
export const BARCODE_MODAL_BUTTON_TITLE = 'Print';
export const BARCODE_TYPE = {
    JCPMASTER: ['MCCERT', 'MASTERCARD RWD'],
};
export const BARCODE_IMAGES_MASTERCARD = '/static-account/assets/images/mc-vrt-pos.svg';
export const BARCODE_IMAGES_BONUSBUCKS =
    '/static-account/assets/images/bonus-bucks-cert-header.svg';
export const BARCODE_IMAGES_BG_LG_BONUSBUCKS =
    '/static-account/assets/images/bonus-bucks-bg-xl.svg';
export const CREATE_ACCOUNT_ERROR_MESSAGES_EMPTY_INPUT_ERROR = 'Please enter valid password';
export const CREATE_ACCOUNT_ERROR_MESSAGES_NON_ALLOWED_SPECIALS_ERROR =
    'Not all special characters are allowed';
export const CREATE_ACCOUNT_ERROR_MESSAGES_MISC_CHARS_ERROR =
    'Password cannot have name, email, penney, jcp or jcpenney, 3+ consecutive characters';
export const CREATE_ACCOUNT_ERROR_MESSAGES_MINIMUM_REQ_NOT_MET_ERROR =
    'Password does not meet requirements';
/* Cookie names */
export const COOKIE_LATLONG = '_userLoc';
export const COOKIE_PRICE_ZONE = 'UPZ';
export const COOKIE_PREVIEW_DATE = 'DP_PREVIEW_DATE';
export const REWARD_PERSONALIZATION_REWARD_AVAILABLE = 'Reward Available';
export const REWARD_PERSONALIZATION_REWARDS_MEMBER = 'Rewards Member';
export const REWARD_PERSONALIZATION_DP_REWARDS_DETAILS = 'DP_REWARDS_DETAILS';
export const REWARD_PERSONALIZATION_POINTS = 'points';
export const REWARD_PERSONALIZATION_MY_ACCOUNT = 'My Account';
export const REWARD_PERSONALIZATION_EARNED_POINT_THRESHOLD = 50;
export const STORE_SERVICES = 'Store Services';
export const JCP_SALON_SIGNED = '/account/dashboard/appointments';
export const SIGNIN = 'Sign In';
export const SIGNIN_CREATEACCOUNT = 'Sign In | Create Account';
export const ENROLLED = 'enrolled';
export const GOOGLE_MAP_API_URL = '//maps.googleapis.com/maps/api/js?key=';
export const GOOGLE_MAP_API_KEY = 'AIzaSyDc0dnG9cxE3N1wqkqY8_iumInyqrAR1bU';
export const GOOGLE_MAP_API_LIBRARY_PARAM_NAME = 'libraries';
export const GOOGLE_MAP_API_PLACE_LIBRARY = 'places';
export const GOOGLE_MAP_AUTO_COMPLETE_ID = 'autocomplete';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_TYPE_STREET_NUMBER = 'street_number';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_TYPE_STREET_NAME = 'route';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_TYPE_POST_CODE = 'postal_code';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_TYPE_STATE = 'administrative_area_level_1';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_TYPE_CITY = 'locality';
export const GOOGLE_MAP_AUTO_COMPLETE_AUTO_COMPLETE_ADDRESS_TYPE_SUB_LOCALITY =
    'sublocality_level_1';
export const GOOGLE_MAP_AUTO_COMPLETE_ADDRESS_FIELDS = ['name', 'address_components'];
export const FIND_STORE_ERROR_MESSAGES_LOCATION_ERROR_MSG_TITLE = 'Location services error';
export const FIND_STORE_ERROR_MESSAGES_LOCATION_NOT_ENABLED =
    'Please enter your Zip Code or City, State, since location services are not enabled due to browser or privacy settings.';
export const FIND_STORE_ERROR_MESSAGES_GEO_LOCATE_ERROR =
    'There was an error with location services, please enter your location';
export const FIND_STORE_ERROR_MESSAGES_EMPTY_ZIP_CODE =
    'Please enter a valid zipcode or choose use my location';
export const FIND_STORE_ERROR_MESSAGES_FUSION_EMPTY_ZIP_CODE = 'Please enter a valid zipcode';
export const FIND_STORE_ERROR_MESSAGES_ZIP_CODE_ERROR = 'Zip code must be 5 digits';
export const FIND_STORE_ERROR_MESSAGES_NATIVE_GEO_LOCATION_ERROR =
    'Please enable Location Services in your system settings to view nearby store';
export const GEO_ERROR_TYPE_GEO_LOCATE_ERROR = 'geoLocateError';
export const GEO_ERROR_TYPE_GEO_UNAVAILABLE = 'geoUnavailable';
export const GEO_ERROR_TYPE_EMPTY_ZIP_CODE = 'emptyZipCode';
export const GEO_ERROR_TYPE_NOT_SUPPORTED = 'browserNotSupported';
export const GEO_ERROR_TYPE_TIMED_OUT = 'timeout';
export const GEO_ERROR_TYPE_AKAMAI_LOCATE_ERROR = 'AkamaiLocateError';
export const GEO_ERROR_TYPE_NATIVE_GEO_LOCATION_ERROR = 'nativeGeoLocationError';
export const useLocationLabel = 'Use My Location';
export const couponBodyCopy = 'Show coupon to sales associate';
export const couponErrorMessages = {
    COUPON_CODE_MISSING: 'Please enter a valid code.',
    INPUT_BOX_PLACEHOLDER: 'Enter promo code...',
    CODE_DOES_NOT_EXIST: 'Code does not exist.',
    COUPON_REQUEST_ERROR:
        'Oops! There is an error on our end. Please try to apply the coupon again.',
    SRV_REWARD_TOKEN_MISSING:
        'Oops! There is an error on our end. Please try to apply the coupon again.',
    COUPON_CODE_EXPIRED:
        'Oh no! The code you typed in has already expired. Please apply an eligible coupon.',
    SRV_COUPON_EXPIRED_ERR:
        'Oh no! The code you typed in has already expired. Please apply an eligible coupon.',
    SERIAL_NUMBER_REQUIRED: 'Serial Number Required.',
    SERIAL_NUMBER_REQ_MESSAGE:
        'This code requires a serial number. Please type in the serial number shown on your coupon or reward.',
    IRIS_COUPON_NO_CART_ITEMS:
        'Oops! We cannot apply this coupon because your cart is empty. We can apply this coupon code once you add an item to your bag.',
    SRV_PROMOCODE_INVALID:
        'Oh no! The code you applied has already expired. Please apply an eligible coupon.',
};

export const COUPON_DRAWER_ERROR_MESSAGES_COUPON_REQUEST_ERROR = [
    'Oops! There is an error on our end.',
    'Please try to apply the coupon again.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_COUPON_CODE_EXPIRED = [
    'Coupon code {COUPONCODE} has already expired.',
    'Please apply an eligible coupon.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_SRV_COUPON_EXPIRED_ERR = [
    'Coupon code {COUPONCODE} has already expired.',
    'Please apply an eligible coupon.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_SERIAL_NUMBER_REQ_MESSAGE = [
    'Coupon code {COUPONCODE} requires a serial number.',
    'Enter the serial number shown on your coupon or reward.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_SRV_PROMOCODE_INVALID = [
    "We don't recognize coupon code {COUPONCODE}.",
    'Please apply an eligible coupon.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_IRIS_COUPON_NO_CART_ITEMS = [
    "We can't apply this coupon just yet.",
    'Start shopping and add this coupon when you have an item in the cart.',
];
export const COUPON_DRAWER_ERROR_MESSAGES_SRV_REWARD_TOKEN_MISSING = [
    'Oops! There is an error on our end.',
    'Please try to apply the coupon again.',
];
export const COUPON_SUBTYPE_PROMOTIONAL = 'PROMOTIONAL';
export const COUPON_SUBTYPE_SHIPPING = 'SHIPPING';
export const COUPON_APPLIED_SUCCESS = 'This coupon has been applied to your cart.';
export const CONTINUE_SHOPPING_LABEL = 'Continue Shopping';
export const IRIS_CONTINUE_SHOPPING_LABEL = 'Shop Sales';
export const COUPON_APPLIED_SHOP_NOW_LABEL = 'Shop Now';
export const COUPONS_APPLIED_LABEL = 'Coupon Applied';
export const COUPONS_APPLIED_LABEL_EXCLUSION = 'Coupon Applied to Cart';
export const COUPONS_NON_RESTRICTED_PAYMENTS = 'Add eligible items to your cart to start saving.';
export const COUPONS_RESTRICTED_PAYMENTS =
    'This coupon code has specific payment conditions. See coupon for details on the specific payment method to receive the best savings for qualifying items.';
export const COUPONS_UPDATED_NON_RESTRICTED_PAYMENTS =
    'This coupon has been applied to your cart. Only one (1) coupon code per purchase.';
export const COUPONS_UPDATE_LABEL = 'Coupon Updated';
export const REWARDS_APPLIED_LABEL = 'Reward Applied';
export const CHECKOUT_LABEL = 'Checkout';
export const SERIAL_APPLY_LABEL = 'Apply Number';
export const COUPONS_NO_CODE_REQUIRED = 'NO CODE NECESSARY';
export const COUPON_TOGGLE_DETAILS_COUPONS_SHOW_DETAILS = 'Show Details';
export const COUPON_TOGGLE_DETAILS_COUPONS_HIDE_DETAILS = 'Hide Details';
export const COUPON_TOGGLE_DETAILS_IRIS_COUPON_DETAILS = 'see details';
export const COUPON_EMPTYCART_LABEL = 'Cannot Apply Coupon';
export const IRIS_COUPON_EMPTYCART_LABEL = 'Your Cart is Empty';

export const COUPON_CATEGORY_ALL = 'online & in store';
export const COUPON_CATEGORY_IN_STORE = 'in store only';
export const COUPON_CATEGORY_ONLINE_ONLY = 'online only';
export const CouponCategories = {
    all: 'Online & In Store',
    instore: 'In Store Only',
    online: 'Online only',
};

export const COUPON_TYPES_APPLIED = 'Applied';
export const COUPON_TYPES_SERIAL = 'Serial';
export const COUPON_TYPES_BARCODE = 'Barcode';
export const couponPrintCheck = ['print', 'print coupon'];

export const SIZE_GUIDE = {
    SIZE_CHART: 'Size Chart',
    SIZE_GUIDE_SECTIONS: 'sections',
};

export const ADJUSTMENT_TYPE_COUPON = 'COUPON';
export const ADJUSTMENT_TYPE_REWARD = 'REWARDSCERT';
export const ADJUSTMENT_TYPE_FREESHIP = 'CouponSHFree';
export const ADJUSTMENT_TYPE_ASSOC_DISCOUNT = 'ASSOCIATEDISCOUNT';
export const COUPON_REDEEMING_CHANNEL_ALL = 'all';
export const COUPON_REDEEMING_CHANNEL_IN_STORE = 'instore';
export const COUPON_REDEEMING_CHANNEL_ONLINE_ONLY = 'online';
export const CouponFindStoreLabel = 'find a store';
export const CouponShopNowLabel = 'shop now';
export const CouponPrintLabel = 'Print';
export const IRISCouponPrintLabel = 'Print Barcode';
export const COUPON_APPLED_METHOD_INPUT_COUPON = 'manual';
export const COUPON_APPLED_METHOD_SELECT_COUPON = 'selected from list';
export const FIND_STORES_API_LIBRARY_PARAM_NAME = 'libraries';
export const FIND_STORES_API_PLACE_LIBRARY = 'places';
export const FIND_STORES_API_URL = '//maps.googleapis.com/maps/api/js?key=';
export const FIND_STORES_BOPIS_DEFAULT_MESSAGE =
    'No results found. Please make sure you entered a valid city, state, or ZIP Code.';
export const FIND_STORES_SLIDER_LOCATION_SERVICE_INFO =
    "Sorry, we can't determine your location. Please provide your city and state, or ZIP code.";
export const FIND_STORES_BOPIS_ELIGIBLE_ERROR =
    'Sorry, there are no stores in the area with the item(s) available for Same Day Pickup.';
export const FIND_STORES_BOPIS_GEO_ERROR = 'Please provide your city and state, or ZIP Code.';
export const FIND_STORES_BOPIS_INVALID_INPUT_ERROR =
    'Sorry, there are no stores nearby. Just in case, make sure you entered a valid city, state, or ZIP Code.';
export const FIND_STORES_GOOGLE_API_KEY = 'AIzaSyCXssOmw3AgN0U-STMPbASl28ymrK4We2U'; // TODO remove this key once enableGeocoder went 100%
export const FIND_STORES_INVALID_INPUT_NAME = 'INVALID_INPUT';
export const FIND_STORES_LINK = 'Link';
export const FIND_STORES_SELECT_STORE = 'Select Store';
export const FIND_STORES_COUNT = 'X-Total-Count';
export const FIND_STORES_UNAVAILABLE = 'Unavailable';
export const FIND_STORES_ACCOUNT = { detailsButton: 'Set My Store' };
export const FIND_STORES_CHANGE_MY_STORE_PAGE_TITLE = 'Change My Store';
export const FIND_STORES_CURB_SIDE_AVAILABLE = 'Curbside Available';
export const FIND_STORES_DEFAULT_TITLE =
    'Enter a City, State or Zip Code to find the closest JCPenney store.';
export const FIND_STORES_FILTER_HEADER = 'Filter by Services';
export const FIND_STORES_FILTER_HEADER_NEW = 'Filter By';
export const FIND_STORES_FIND_A_STORE_PAGE_TITLE = 'Find A Store';
export const FIND_STORES_INVALID_INPUT_CONTENT =
    'Please make sure you entered a valid zip code or city, state.';
export const FIND_STORES_INVALID_INPUT_MSG = 'Please enter valid city, state or ZIP code';
export const FIND_STORES_NEW_ORDER_MESSAGE = 'Order by 3PM and get it today';
export const FIND_STORES_NO_SDPU_STORES_MESSAGE =
    "We're sorry…there are no JCP stores in that location with the item(s) available for Same Day Pickup.";
export const FIND_STORES_NO_SDPU_STORES_TITLE =
    'Sorry, there are no JCP stores in that location with the item(s) available for Same Day Pickup.';
export const FIND_STORES_NO_STORE_PAGE_CONTENT =
    'Please make sure you entered a valid zip code or city, state.';
export const FIND_STORES_NO_STORE_PAGE_TITLE =
    "Sorry, we couldn't find any stores near your search.";
export const FIND_STORES_NO_STORES_MESSAGE =
    "We're sorry...we couldn't find any jcp stores that match your search";
export const FIND_STORES_ORDER = { detailsButton: 'Select' };
export const FIND_STORES_ORDER_MESSAGE =
    'Same day pickup items ordered after 3 PM will be available the next day';
export const FIND_STORES_PAGE_SIZE = 7;
export const FIND_STORES_PDP_FIND_STORES_PAGE_SIZE = 25;
export const FIND_STORES_SELECT_A_STORE_HEADER_TEXT = 'Select a Store';
export const FIND_STORES_SELECT_A_STORE_PAGE_TITLE = 'Select A Store';
export const FIND_STORES_SELECT_THIS_STORE = 'Select This Store';
export const SEARCH_MAPS_GOOGLE_MAPS_URL = 'https://www.google.com/maps/dir/?api=1&destination=';
export const SEARCH_MAPS_APPLE_MAPS_URL = 'https://maps.apple.com?address=';
export const GENERIC_API_ERROR_MESSAGE =
    'There was an error while processing your request. Please try again.';
export const COUPON_TYPEAHEAD_TRIGGER_LENGTH = 3;
export const IN_STORE_ONLY_COUPONS = 'INSTOREONLYCOUPONS';
export const ACCESS_TOKEN = 'Access_Token';
export const REFRESH_TOKEN = 'Refresh_Token';
export const ACCOUNT_ID = 'ACCOUNT_ID';
export const DP_USER_STATE = 'DP_USER_STATE';
export const DP_USER_EMAIL = 'DP_USER_EMAIL';
export const DP_APPLY_SYF = 'DP_APPLY_SYF';
export const DP_FIRST_NAME = 'DP_FIRST_NAME';
export const DP_USER_NAME = 'DP_USER_NAME';
export const DP_REWARDS_STATUS = 'DP_REWARDS_STATUS';
export const DP_REWARDS_CERTS = 'DP_REWARDS_CERTS';
export const DP_SAVED_ITEMS_COUNT = 'DP_SAVED_ITEMS_COUNT';
export const DP_SAVED_ITEMS = 'DP_SAVED_ITEMS';
export const DP_HAS_JCP_CARD = 'DP_HAS_JCP_CARD';
export const DP_JCP_CARD = 'DP_JCP_CARD';
export const ANALY_CUST_STATE = 'ANALY_CUST_STATE';
export const ANALY_LOGIN = 'logged-in';
export const ANALY_GUEST = 'guest';
export const ANALY_LOGIN_RECOG = 'logged-in|recognized';
export const ANALY_RECOG_LOGIN = 'recognized|logged-in';
export const ANALY_RECOG_GUEST = 'recognized|guest';
export const DP_CHECKOUT_SAVED_ITEMS = 'DP_CHECKOUT_SAVED_ITEMS';
export const DP_CHECKOUT_SAVED_ITEMS_PAYLOAD = 'DP_CHECKOUT_SAVED_ITEMS_PAYLOAD';
export const DP_USER_TOKEN = 'DP_USER_TOKEN';
export const DP_SAVED_ITEMS_PAYLOAD = 'DP_SAVED_ITEMS_PAYLOAD';
export const DP_VISITOR_ID = 'DP_VISITOR_ID';
export const DP_USER_DEFAULT_STORE = 'DP_USER_DEFAULT_STORE';
export const DEFAULT_STORE = 'defaultStore';
export const DP_SFL_ID = 'DP_SFL_ID';
export const TOKEN_API_STATUS = 'TOKEN_API_STATUS';
export const DEFAULT_CURRENCY = 'USD';
export const ITEM_COUNT = 'ItemCount';
export const DESKTOP_ITEM_COUNT = 'DP_ORDER_INFO';
export const DP_FULL_ACCESS_EXPIRY = 'DP_FULL_ACCESS_EXPIRY';

export const ANALYTICS_COUPON_APPLIED_SUCCESS = 'COUPON_APPLIED_SUCCESS';
export const ANALYTICS_SELECT_STORE_SUCCESS = 'SELECT_STORE_SUCCESS';
export const BRANCH_IO_BRANCHIO_TEST_KEY = 'key_test_mlBoOH9ybO7NGpeCWvvNTegaBtev2hgv';
export const BRANCH_IO_BRANCHIO_LIVE_KEY = 'key_live_ekymMOXuhN0OGkfD1ytRmhmluEozYgdl';

export const OK_LABEL = 'OK';
export const CART_PAGE_URL = '/cart';
export const CART_PAGE_URL_LIST = [
    '/cart',
    '/cart/signin',
    '/cart/resetpassword',
    '/cart/giftcard',
];
export const CHECKOUT_PAGE_URL = '/checkout';
export const CART_PAGE_SIGNIN_URL = '/cart/signin';
export const COOKIES = 'Cookies';
export const ACCOUNT_LOCKED_PAGE_URL = '/checkout/account-locked';
export const CART_YODA_EXPERIENCE = 'OFF';
export const CLASSIC_CART_PAGE_URL = '/cart#/';
// =Constants for error message handling. Don't remove them;
export const SET_SERVICE_ERROR = 'SET_SERVICE_ERROR';
export const CLEAR_SERVICE_ERROR = 'CLEAR_SERVICE_ERROR';
export const GENERIC_API_ERROR = 'GENERIC_API_ERROR';
export const INITIATE_CHECKOUT_AGAIN = 'INITIATE_CHECKOUT_AGAIN';
export const SRV_ITEM_NOT_FOUND = 'SRV_ORD_ERR_ITM_NF';
export const SRV_COMMERCE_ITEMNOTFOUND = 'SRV_COMMERCE_ITEMNOTFOUND';
export const SRV_PROMOTION_NOTFOUND = 'SRV_PROMOTION_NOTFOUND';
export const ACTION_NOT_ALLOWED_FOR_RECOGNIZED = 'ACTION_NOT_ALLOWED_FOR_RECOGNIZED';
export const SRV_BILLING_ADDRESS_INVALID = 'SRV_BILLING_ADDRESS_INVALID';
export const SRV_ORDER_EMAILNOTFOUND = 'SRV_ORDER_EMAILNOTFOUND';
export const ADD_TO_REGISTRY_MESSAGES_ID = 'ADD_TO_REGISTRY_MESSAGES_ID';

export const FIND_STORE_THEMES_FIND_A_STORE_PAGE = 'findAStorePage';
export const FIND_STORE_THEMES_GALLERY = 'gallery';
export const FIND_STORE_THEMES_SELECT_A_STORE = 'selectAStore';
export const FIND_STORE_THEMES_PDP_MAJOR_APPLIANCES = 'pdpMajorAppliances';
export const FIND_STORE_THEMES_VISIT_STORE_TO_PURCHASES = 'visitStoreToPurchase';
export const FIND_STORE_THEMES_PDP = 'pdp';
export const FIND_STORE_THEMES_CHECKOUT_SHIPPING = 'checkoutShipping';
export const SRV_PRODUCT_NOTFOUND = 'SRV_PRODUCT_NOTFOUND';
export const SRV_REFRESH_TOKEN_EXPIRED = 'SRV_REFRESH_TOKEN_EXPIRED';
export const GUEST_USER = 'GUEST_USER';
export const APPLIANCE_MESSAGE_TITLE =
    'Major Appliances are available for purchase nationwide. You can place an order on jcp.com, in an area store(see below), or call 1-844-JCP-APPL(1-844-527-2775)';
export const FORGOT_PASSWORD = 'Forgot Password';
export const THRESOLD_SAVEDITEMS = 50;
export const CARTPAGE_LAST_VISIT_TIME = 'cartPageLastVisitTime';

export const RECOMMENDATION_SOURCE_TYPE_CERTONA = 'certona';
export const RECOMMENDATION_SOURCE_TYPE_ADOBE = 'adobe';
export const RECOMMENDATION_SOURCE_TYPE_CRITEO = 'criteo';
export const RECOMMENDATION_SOURCE_TYPE_GET_ALL_SAVE_FOR_LATER = 'getAllSaveForLater';
export const RECOMMENDATION_SOURCE_TYPE_SOURCE = 'rrplacementtype=';
export const RECOMMENDATION_SOURCE_TYPE_HOME = 'HOME';
export const RECOMMENDATION_SOURCE_TYPE_DEPARTMENT = 'DEPARTMENT';
export const RECOMMENDATION_SOURCE_TYPE_HOME_BRAND = 'home-brand';
export const RECOMMENDATION_SOURCE_TYPE_DEP_BRAND = 'dep-brand';

export const apiErrorLog = {
    genericError: {
        type: 'generic_api_error',
        description: 'generic api error',
    },
    statusCodeNot200: {
        type: 'api_status_code_not_200',
        description: 'api failed with status code',
    },
    emptyResponseData: {
        type: 'response_data_empty',
        description: 'api response data is empty',
    },
};

export const LOCATION_SERVICE_COOKIES_AKAMAI_STORE = 'akamaiStore';
export const LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE = 'userPreferredStore';
export const LOCATION_SERVICE_COOKIES_USER_STORE = 'userStore';
export const PLCC_BANNER_CARDS_JCP_CARD = 'JCP';
export const PLCC_BANNER_CARDS_PLATINUM_TIER = 'platinum';
export const ATC_HEADER_OPTIONS_ADDITIONAL_OPTIONS = 'Additional Options';
export const ATC_HEADER_OPTIONS_DETAILS = 'Details';
export const pdpHeaderOptions = {
    CHOOSE_OPTIONS: 'Choose Options',
};

export const headerStore = {
    NO_STORE_LOCATED: 'JCPenney Stores',
};

export const HEADER_STORE_SELECTION_SLIDER_TITLE = 'Find a Pickup Store';
export const HEADER_STORE_SELECTIONSLIDER_VISIT_STORE_TITLE = 'Check Store Availability';

export const STORE_CLICK_STREAM_LOGS_LAT_LONG = 'Get Stores by Lat Long';
export const STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_CART = 'Get Stores for Cart Success';
export const STORE_CLICK_STREAM_LOGS_GET_STORES_FOR_LOGGED_IN_USER =
    'Get Stores for Logged In User Success';
export const STORE_CLICK_STREAM_LOGS_UPDATE_USER_PREFFERED_STORE =
    'Update User Preffered Store Info';

export const sizeChartModalOptions = {
    maxPercentageModalWidth: 0.8,
    paddingBetweenChartAndImage: 40,
    totalModalPadding: 64,
};
export const CSE_CID = 'cse';
export const PLA_REC_PAGE_TYPE = 'PLAPRODUCT';
export const PRODUCT = 'PRODUCT';

export const X_CLIENT_SOURCE_SLIDER = 'SLIDER';
export const X_CLIENT_SOURCE_PDP = 'PDP';
export const X_CLIENT_SOURCE_MINI_PDP = 'MINI_PDP';
export const IEJCPLogo = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAAAqBAMAAABioPVzAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURecTJOcSI+cTJP////nBxfzi5PBpc+1IVeowP/OJkf7w8vanrekhMvF6hPvT1vSYnxCvA7cAAAACdFJOU/6fnByP3gAAAiBJREFUSMftlc1r1EAYxvdP6N/wsPlYu23BN8lmp7VCE/xAT01pq8ekBFwEIQrrTYiWelFh9SAeDYoIXjYFbx5WD7LiZT331IOoN7149J00wU1ZFm2uO5CZ5J2ZX+Z95smkhqplrjZDzBAzxL8hVNfn+o63eo4b13Uvxv+NaFAP+EBctgHZnDkRQvfoxz69YoR5lSg6CaJOW8BDnkrruMcX9O6Aq/vNLmf1MdZuy4FaV4YgAxMQgZkLIKcnLWgOLcdQzA6dBdKWR5eBhYSu8OAR4NkTEI6Fv4jUQpApowhuekhNIgN4zA9rGq1Bo/4ERLIyhnAsPWnvpQYU2nxAz5GKpx2KVdra89pwbMznYpURcmaB0Mle4uXWKVJ47C9brqpB/iLFGAoEbc4PUxEvw2fUX6QwvEEjRQBfWkhXoJL/3gzDHYqGJgILUxPhYsaKbKgvXxcw4jSn79/NYn6DIseehHDaY4gLLAK71P1ZRggZ8zX6natZQoxwU8hN9bNErkPqIHtKiFPiaLjzprBegbgVzZPPczbZ5MYgF2WBt06Ny4g6L/Yz9wVitexOlQyHbdX0aGOfRJQjmp554GyXEXqyfOBxCkOyjhn8kLLQJynCVxRbs3tkrXEEOtJaMu/1Ywj1cCNL7dH3F2/lx55LtfP62wD1S3xzHk/YkG4E/Z2Qx0HhzQpHzhLFVRGKUfngK7xZAXGtN/uPzBBVEbXpZe4PRm7OOLsML+8AAAAASUVORK5CYII=`;
export const BUNDLE = 'BUNDLE';
export const SALON_SYNDICATE_URL = '/signin?next=/account/dashboard/appointments';
export const ADI_ANALYTICS_SRC_CODE = {
    ADD_GC_REJECT: '117',
    ADD_GC_APPROVED: '118',

    ADD_CC_GUEST_CHALLENGE: '8',
    ADD_CC_GUEST_REJECT: '9',
    ADD_CC_GUEST_APPROVED: '10',

    ADD_CC_REGISTERED_CHALLENGE: '14',
    ADD_CC_REGISTERED_REJECT: '15',
    ADD_CC_REGISTERED_APPROVED: '16',

    UPDATE_CC_GUEST_REJECT: '21',
    UPDATE_CC_GUEST_APPROVED: '22',

    UPDATE_CC_REGISTERED_REJECT: '12',
    UPDATE_CC_REGISTERED_APPROVED: '13',

    ADD_CC_STP_APPROVED: '23',
    ADD_CC_STP_REJECT: '24',

    UPDATE_CC_STP_APPROVED: '25',
    UPDATE_CC_STP_REJECT: '26',

    CAM_UPDATE_CC_REJECT: '17',
    CAM_UPDATE_CC_APPROVED: '18',
    CAM_ADD_CC_REJECT: '19',
    CAM_ADD_CC_APPROVED: '20',
    SLIDER_CREATE_ACCOUNT_REJECT: '101',
    SLIDER_CREATE_ACCOUNT_APPROVED: '104',
    CAM_CREATE_ACCOUNT_REJECT: '105',
    CAM_CREATE_ACCOUNT_APPROVED: '108',
    CART_CREATE_ACCOUNT_REJECT: '109',
    CART_CREATE_ACCOUNT_APPROVED: '112',
    OCP_CREATE_ACCOUNT_REJECT: '113',
    OCP_CREATE_ACCOUNT_APPROVED: '116',
    INITIATE_CHECKOUT_REJECT: '119',
    INITIATE_CHECKOUT_APPROVED: '120',
    SCC_CHECKOUT_REJECT: '5',
    SCC_CHECKOUT_APPROVED: '7',
    CAM_SIGNIN_ACCOUNT_REJECT: '177',
    CAM_SIGNIN_ACCOUNT_APPROVED: '176',
    SLIDER_SIGNIN_ACCOUNT_APPROVED: '121',
    SLIDER_SIGNIN_ACCOUNT_REJECT: '122',
    CAM_FORGOT_PASSWORD_REJECT: '179',
    CAM_FORGOT_PASSWORD_APPROVED: '178',
    SLIDER_FORGOT_PASSWORD_REJECT: '158',
    SLIDER_FORGOT_PASSWORD_APPROVED: '157',

    UPDATE_ADDRESS_PROFILE_CCO_APPROVED: '169',
    UPDATE_ADDRESS_PROFILE_CCO_REJECT: '170',

    ADD_ADDRESS_PROFILE_CCO_APPROVED: '167',
    ADD_ADDRESS_PROFILE_CCO_REJECT: '168',

    DELETE_ADDRESS_PROFILE_CCO_APPROVED: '171',
    DELETE_ADDRESS_PROFILE_CCO_REJECT: '172',

    GET_GIFT_CARD_BALANCE_APPROVED: '153',
    GET_GIFT_CARD_BALANCE_REJECT: '154',

    UPDATE_ADDRESS_PROFILE_CAM_APPROVED: '163',
    UPDATE_ADDRESS_PROFILE_CAM_REJECT: '164',

    ADD_ADDRESS_PROFILE_CAM_APPROVED: '173',
    ADD_ADDRESS_PROFILE_CAM_REJECT: '174',

    DELETE_ADDRESS_PROFILE_CAM_APPROVED: '165',
    DELETE_ADDRESS_PROFILE_CAM_REJECT: '166',

    UPDATE_PROFILE_CAM_APPROVED: '155',
    UPDATE_PROFILE_CAM_REJECT: '156',

    CAM_RESET_PASSWORD_APPROVED: '161',
    CAM_RESET_PASSWORD_REJECT: '162',
    CART_SIGNIN_FORGOT_PASSWORD_APPROVED: '159',
    CART_SIGNIN_FORGOT_PASSWORD_REJECT: '160',
    CART_SIGNIN_PAGE_APPROVED: '123',
    CART_SIGNIN_PAGE_REJECT: '124',
    CART_REWARDS_SIGNIN_SLIDER_APPROVED: '125',
    CART_REWARDS_SIGNIN_SLIDER_REJECT: '126',

    SERIAL_REWARDS_APPROVED: '127',
    SERIAL_REWARDS_REJECT: '128',

    SERIAL_COUPONS_APPROVED: '137',
    SERIAL_COUPONS_REJECT: '138',

    ADD_ADDRESS_PROFILE_BILLING_APPROVED: '167',
    ADD_ADDRESS_PROFILE_BILLING_REJECT: '168',
};
export const ANALYTICS_SRC_CODE = {
    SLIDER_CREATE_ACCOUNT_BUSINESS: '102',
    SLIDER_CREATE_ACCOUNT_SYSTEM: '103',
    CAM_CREATE_ACCOUNT_BUSINESS: '106',
    CAM_CREATE_ACCOUNT_SYSTEM: '107',
    CART_CREATE_ACCOUNT_BUSINESS: '110',
    CART_CREATE_ACCOUNT_SYSTEM: '111',
    OCP_CREATE_ACCOUNT_BUSINESS: '114',
    OCP_CREATE_ACCOUNT_SYSTEM: '115',
};
export const ADI_ANALYTICS_DETAILED_ERROR = {
    ADD_GC: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding GC from ADI',
        actionName: 'add_GC_error',
    },
    ADD_CC_GUEST: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding CC from ADI',
        actionName: 'add_CC_guest_error',
    },
    ADD_CC_REGISTERED: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding CC from ADI for Registered user',
        actionName: 'add_CC_Registered_error',
    },
    ADD_CC_STP: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding CC from ADI for Save card to profile',
        actionName: 'add_CC_savetoprofile_error',
    },
    UPDATE_CC_GUEST: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding CC from ADI',
        actionName: 'update_CC_guest_error',
    },
    UPDATE_CC_REGISTERED: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in Adding CC from ADI for Registered user',
        actionName: 'update_CC_Registered_error',
    },
    UPDATE_CC_STP: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in updating CC from ADI for Save card to profile',
        actionName: 'update_CC_savetoprofile_error',
    },
    CAM_ADD_CC: {
        fromPage: 'd:account:wallet',
        errMessage: 'Error in Adding CC from wallet',
        actionName: 'CAM_add_CC_error',
    },
    CAM_UPDATE_CC: {
        fromPage: 'd:account:wallet',
        errMessage: 'Error in Updating CC from wallet',
        actionName: 'CAM_update_CC_error',
    },
    INITIATE_CHECKOUT: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in initiatecheckout adi',
        actionName: 'initiate_Checkout_error',
    },
    SCC_CHECKOUT: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error in scc initiatecheckout adi',
        actionName: 'scc_initiate_Checkout_error',
    },
    SLIDER_CREATE_ACCOUNT: {
        fromPage: 'd:account:create account',
        errMessage: 'Error while creating account from slider',
        actionName: 'Slider_create_account_error',
    },
    CAM_CREATE_ACCOUNT: {
        fromPage: 'd:account:my account',
        errMessage: 'Error while creating account from CAM',
        actionName: 'CAM_create_account_error',
    },
    CART_CREATE_ACCOUNT: {
        fromPage: 'd:checkout:checkout login',
        errMessage: 'Error while creating account from Cart',
        actionName: 'Cart_create_account_error',
    },
    OCP_CREATE_ACCOUNT: {
        fromPage: 'd:checkout:checkout order confirmation',
        errMessage: 'Error while creating account from OCP',
        actionName: 'OCP_create_account_error',
    },
    CAM_RESET_PASSWORD: {
        fromPage: 'd:account:reset password active',
        errMessage: 'Error while password reset from forgot password page',
        actionName: 'Reset_password_account_error',
    },
    SLIDER_SIGNIN_ACCOUNT: {
        fromPage: 'd:account:Signin account',
        errMessage: 'Error while Signin account from slider',
        actionName: 'Slider_signin_account_error',
    },
    CAM_SIGNIN_ACCOUNT: {
        fromPage: 'd:account:login',
        errMessage: 'Error while Signin account from CAM',
        actionName: 'CAM_signin_account_error',
    },
    SLIDER_FORGOT_PASSWORD: {
        fromPage: 'd:account:Forgot Password Slider',
        errMessage: 'Error while forgot password from slider',
        actionName: 'Slider_forgot_password_error',
    },
    CAM_FORGOT_PASSWORD: {
        fromPage: 'd:account:Forgot Password Account',
        errMessage: 'Error while forgot password from CAM',
        actionName: 'CAM_forgot_password_error',
    },
    CART_SIGNIN_FORGOT_PASSWORD: {
        fromPage: 'd:checkout:checkout reset password',
        errMessage: 'Error while forgot password from cart signin',
        actionName: 'CART_signin_forgot_password_error',
    },
    UPDATE_ADDRESS_PROFILE_CCO: {
        fromPage: 'd:checkout:checkout shipping',
        errMessage: 'Error while adding shipping',
        actionName: 'Shipping_add_address_CCO_error',
    },
    ADD_ADDRESS_PROFILE_CCO: {
        fromPage: 'd:checkout:checkout shipping',
        errMessage: 'Error while updating shipping',
        actionName: 'Shipping_update_address_CCO_error',
    },
    ADD_ADDRESS_PROFILE_BILLING: {
        fromPage: 'd:checkout:checkout payment',
        errMessage: 'Error while updating billing address',
        actionName: 'Billing_add_address_CCO_error',
    },
    UPDATE_PROFILE_CAM: {
        fromPage: 'd:account:address page',
        errMessage: 'Error while updating profile',
        actionName: 'CAM_update_profile_error',
    },
    GET_GIFT_CARD_BALANCE: {
        fromPage: 'd:cart:gift card balance',
        errMessage: 'Error while fetching gift card balance',
        actionName: 'Gift_card_balance_error',
    },
    UPDATE_ADDRESS_PROFILE_CAM: {
        fromPage: 'account:wallet',
        errMessage: 'Error while adding address account info',
        actionName: 'Shipping_add_address_CAM_error',
    },
    ADD_ADDRESS_PROFILE_CAM: {
        fromPage: 'account:wallet',
        errMessage: 'Error while updating account info',
        actionName: 'Shipping_update_address_CAM_error',
    },
    CART_SIGNIN_PAGE: {
        fromPage: 'd:cart:Signin',
        errMessage: 'Error while signin account from cart signin page',
        actionName: 'cart_signin_error',
    },
    CART_REWARDS_SIGNIN_SLIDER: {
        fromPage: 'd:cart:Rewards Signin Slider',
        errMessage: 'Error while signin from cart rewards slider',
        actionName: 'cart_rewards_signin_error',
    },
    SERIAL_REWARDS: {
        fromPage: 'd:cart:Rewards Signin Slider',
        errMessage: 'Error in serial restricted rewards',
        actionName: 'serial_restricted_rewards_error',
    },
    SERIAL_COUPONS: {
        fromPage: 'd:cart:Rewards Signin Slider',
        errMessage: 'Error in serial restricted coupons',
        actionName: 'serial_restricted_coupons_error',
    },
};
